import Back from "../components/pricing/Back"
import PriceCard from "../components/pricing/PriceCard"
// import img from "../../public/icons/pricing.jpg"
import "../components/pricing/price.css"
import {useEffect, useState} from "react";
import {Bounce, toast} from "react-toastify";
import OrderService from "../services/order.service";
import useRazorpay from "react-razorpay";
import {useNavigate} from "react-router-dom";
const Pricing = () => {
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();
    const info= sessionStorage.getItem("userInfo");
    const [userInfo,setUserInfo] = useState();
    const [amount,setAmount] = useState(0);
    const [razorpayPaymentId, setRazorpaypaymentId] = useState("");
    const [razorpayOrderId, setRazorpayorderId] = useState("");
    const [razorpaySignature, setRazorpaysignature] = useState("");

    useEffect(() => {
        if(info){
            setUserInfo(JSON.parse(sessionStorage.getItem("userInfo")))
        }
    }, [info]);
    const handleClick = async (item) => {
        if (userInfo === undefined || userInfo === "" || userInfo === null) {
            toast.warn('Please login to before you get started ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                theme: "light",
                transition: Bounce,
            });
            return;
        } else {
            OrderService.createOrder({
                userId: userInfo?.id,
                customerName: userInfo?.name,
                email : userInfo?.contact?.emailId,
                phoneNumber : userInfo?.contact?.mobileNo,
                amount : item.price,
                planName: item.plan
            }).then(response=>{
                if(response!=null && response.data!=null && response.data?.success){
                    const resp = response.data.response;
                    createRazorPayment(resp);
                }
            });

            // console.log(response);
        }

        const createRazorPayment = (response) => {
            const options = {
                description: item.plan,
                // image:
                //     `http://localhost:9001${location.state.photosImagePath.split(",")[0]}`,
                currency: "INR",
                // key: "rzp_test_Rdab4sKKJnl5EP", // Enter the Key ID generated from the Dashboard
                key: response.secretKey,
                amount: response.applicationFee, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                name: userInfo?.name,
                order_id: response.razorpayOrderId,
                handler: function (response) {
                    setRazorpaypaymentId(response.razorpay_payment_id);
                    setRazorpayorderId(response.razorpay_order_id);
                    setRazorpaysignature(response.razorpay_signature);

                    updateOrder({
                        razorpayOrderId : response.razorpay_order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpaySignature: response.razorpay_signature,
                        paymentStatus: "Success"
                    });
                    navigate("/profiledetail/"+userInfo?.id);
                },
                prefill: {
                    name: sessionStorage.getItem("username"),
                    email: sessionStorage.getItem("username"),
                    contact: "9999999999",
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            const rzp1 = new Razorpay(options);

            rzp1.on("payment.failed", function (response) {
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
                updateOrder({
                    razorpayOrderId : razorpayOrderId,
                    razorpayPaymentId: "",
                    razorpaySignature: "",
                    paymentStatus: "Failed"
                });
            });

            rzp1.open();
        }

        async function updateOrder(credentials) {
            return OrderService.updateOrder(credentials).then((data) => data);
        }
    };
  return (
    <>
      <section className='pricing mb'>
        {/*<Back name='30 days money back guarantee' title='No Extra Fees. Friendly Support' cover={"images/icons/pricing.jpg"} />*/}
        <div className='price' style={{marginLeft:'2.2rem'}}>
          <PriceCard handleClick={handleClick}/>
        </div>
      </section>
    </>
  )
}

export default Pricing
