import {
  GET_SELLERS_BEGIN,
  GET_SELLERS_SUCCESS,
  GET_SELLERS_ERROR,
  GET_SINGLE_SELLER_BEGIN,
  GET_SINGLE_SELLER_SUCCESS,
  GET_SINGLE_SELLER_ERROR, GET_CATEGORY_BEGIN, GET_CATEGORY_SUCCESS, GET_CATEGORY_ERROR,
} from '../actions'

const sellers_reducer = (state, action) => {

  if (action.type === GET_CATEGORY_BEGIN) {
    return { ...state, category_loading: true }
  }
  if (action.type === GET_CATEGORY_SUCCESS) {
    return {
      ...state,
      category_loading: false,
      categoryData: action.payload
    }
  }
  if (action.type === GET_CATEGORY_ERROR) {
    return { ...state, category_loading: false, category_error: true }
  }

  if (action.type === GET_SELLERS_BEGIN) {
    return { ...state, sellers_loading: true }
  }
  if (action.type === GET_SELLERS_SUCCESS) {
    return {
      ...state,
      sellers_loading: false,
      sellers: action.payload
    }
  }
  if (action.type === GET_SELLERS_ERROR) {
    return { ...state, sellers_loading: false, sellers_error: true }
  }
  if (action.type === GET_SINGLE_SELLER_BEGIN) {
    return {
      ...state,
      single_seller_loading: true,
      single_seller_error: false,
    }
  }
  if (action.type === GET_SINGLE_SELLER_SUCCESS) {
    return {
      ...state,
      single_seller_loading: false,
      single_seller: action.payload,
    }
  }
  if (action.type === GET_SINGLE_SELLER_ERROR) {
    return {
      ...state,
      single_seller_loading: false,
      single_seller_error: true,
    }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default sellers_reducer
