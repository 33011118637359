import React from "react"
import { price } from "./Data"

const PriceCard = (props) => {
    const handleClick = (item) => {
      props.handleClick(item);
    }
  return (
    <>
      <div className='content flex' style={{gap:'1rem',marginTop:'8rem'}}>
        {price.map((item, index) => (
            <div className={'box shadow '+ (item.best ? 'price-card-border':'')} key={index}>
                <div className='topbtn'>
                    {
                        item.best && <span
                         className="bg-primary font-semibold px-4 py-1 rounded-full uppercase text-xs text-white">{item.best}</span>
                    }
                    </div>

                <h3>{item.plan}</h3>
                <h1>

                    {item.price ==='free'?'free':<span>&#x20b9;{item.price}</span>}
                </h1>
                <p>{item.ptext}</p>

                <ul>
                    {item.list.map((val) => {
                        const {icon, text, change} = val
                        return (
                            <li>
                                <label
                                    style={{
                                        background: change === "color" ? "#dc35451f" : "#27ae601f",
                                        color: change === "color" ? "#dc3848" : "#27ae60",
                                    }}
                                >
                                    {icon}
                                </label>
                                <p>{text}</p>
                            </li>
                        )
                    })}
                </ul>
                {
                    item.best ?<button
                    className="btn btn-primary" style={{width:'100%'}}
                    onClick={()=>handleClick(item)}
                >
                   Buy Now
                </button>: <button className="btn btn-light" style={{    display: 'flex',
                        border: '1px solid',
                        justifyContent: 'space-around',
                        textAlign: 'center',
                        alignItems: 'center',
                        width:'100%'
                    }}  onClick={()=>handleClick(item)}>Buy Now</button>
                }
            </div>
        ))}
      </div>
    </>
  )
}

export default PriceCard
