import axios from "axios";

export default class SellerService {

    static API_URL = String(process.env.REACT_APP_SERVICE_URL);
    static API_PREFIX_URL = String(process.env.REACT_APP_SELLER_SERV_PREFIX);
    static RAT_PREFIX_URL = String(process.env.REACT_APP_RATING_SERV_PREFIX);
    static URL= SellerService.API_URL.concat(SellerService.API_PREFIX_URL);
    static RATE_URL= SellerService.API_URL.concat(SellerService.RAT_PREFIX_URL);
    static ENQUIRY_URL= SellerService.API_URL.concat(SellerService.ENQUIRY_PREFIX_URL);

    static createSeller = (request) => {
        return axios.post(SellerService.URL + '/create',request,{
            headers: {
                // 'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
    static getBySellerMdn = (mdn) => {
        return axios.get(SellerService.URL + '/getSellerByMdn/'+mdn,{
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }

    static getBySellerById = (id) => {
        return axios.get(SellerService.URL + '/getSellerById/'+id,{
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }

    static getRatingsBySellerById = (id) => {
        return axios.get(SellerService.RATE_URL + '/getAllRatingsBySellerId/'+id,{
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }

    static enquirySeller = (request) => {
        return axios.post(SellerService.ENQUIRY_URL + '/create',request,{
            headers: {
                // 'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
}