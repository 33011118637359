// import UserImg from '../../public/icons/user2.png';
// import Profileimg from '../../public/icons/profileImg.png';
// import './Contact.css';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import SellerService from "../services/seller.service";
import {useLoading} from "../context/LoadingContext";
import moment from "moment";

const ProfileDetail = () => {
    const { id } = useParams();
    const {setLoading} = useLoading();
    const [seller,setSeller] = useState({});
    const [sellerRatings,setSellerRatings] = useState([]);
    useEffect(() => {
        if (id !== null) {
            if (id !== undefined) {
                SellerService.getBySellerById(id).then(response=>{
                    if(response!== null && (response.data!=="")){
                        setLoading(false);
                        setSeller(response.data);
                        if(response.data?.id){
                            setLoading(true);
                            SellerService.getRatingsBySellerById(id).then(ratingResp=>{
                                if(response!== null && (response.data!=="")) {
                                    setLoading(false)
                                    setSellerRatings(response.data);
                                }
                            })
                        }
                    } else {
                        setLoading(false);
                    }
                }).catch(error=>{console.log(error)});
            }
        }
    }, [id]);
    const getAverageRating = (sellerRatings) => {
        const ratings=sellerRatings?.map((rating) => { return rating.rating})
        const sum = ratings?.reduce((total, rating) => total + rating, 0);

        // Calculate the average rating.
        // Return the average rating.
        return sum / ratings.length;
    }
    const calculateTime = (date) => {
        var today = new Date();
        var Christmas = moment(date);
        var diffMs = (Christmas - today); // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        if(diffDays){
            return diffDays+" days ago";
        }else if(diffHrs){
            return diffHrs+" hours ago";
        }else if(diffMins){
            return diffMins+" minutes ago";
        }else {
            return ((diffMs % 60000) / 1000).toFixed(0)+" seconds ago"
        }
    }
  return (
    <>{
        seller && <div>

            <div className="mx-auto my-5 p-5" style={{marginTop: '1.95rem'}}>
                <div className="md:flex no-wrap md:-mx-2 overflow-y-scroll" style={{minHeight: '96vh'}}>
                    <div className="w-full md:w-3/12 md:mx-2">

                        <div
                            className="bg-white p-3 border-t-4 border-primary shadow-lg py-4 px-4 bg-white rounded-3xl">
                            {/* <div className="image overflow-hidden">
                        <img className="h-auto w-full mx-auto"
                            src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                            alt="" />
                    </div> */}


                            <div className="flex flex-wrap items-center" data-path="0.0.4.0.0">
                                <img className="mr-6" src={"../images/icons/profileImg.png"} alt="" width="40px"/>
                                <div style={{display: "flex", alignItems: 'center'}}>
                                    <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">{seller?.name} </h1>
                                    <img src={`../images/icons/verified-seller-primary.png`} alt="not found"/>
                                </div>
                                <div>
                                    {
                                        sellerRatings?.length > 0 &&
                                        <div className="flex justify-between mt-3">
                                            <div className="flex-align-center gap-x-2">
                                                <ReactStars
                                                    size={16}
                                                    isHalf={true}
                                                    activeColor="#ffd700"
                                                    value={getAverageRating(sellerRatings)}
                                                    edit={false}
                                                />
                                                <div
                                                    className="text-gray-600 ml-2 text-sm md:text-base mt-1">{sellerRatings?.length} reviews
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <p className="text-sm text-gray-500 hover:text-gray-600 leading-6">{seller?.orgDescription}</p>
                            <ul
                                className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                <li className="flex items-center py-3">
                                    <span>Status</span>
                                    <span className="ml-auto"><span
                                        className="bg-primary py-1 px-2 rounded text-white text-sm">Active</span></span>
                                </li>
                                <li className="flex items-center py-3">
                                    <span>Member since</span>
                                    <span className="ml-auto">{moment(seller?.createdDate).format('LL')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="my-4"></div>
                        {
                            seller?.subscriptionDtls && <div
                                className="bg-white p-3 shadow-lg py-4 px-4 bg-white rounded-3xl">
                                {/* <div className="image overflow-hidden">
                        <img className="h-auto w-full mx-auto"
                            src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                            alt="" />
                    </div> */}


                                <div className="flex flex-wrap items-center" data-path="0.0.4.0.0">
                                    {/*<span className="tracking-wide"></span>*/}
                                    <span
                                        className="text-gray-900 font-bold text-xl leading-8 my-1">Subscription Details</span>
                                </div>
                                <ul
                                    className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                    <li className="flex items-center py-3">
                                        <span>Plan Name</span>
                                        <span className="ml-auto"><span>{seller?.subscriptionDtls?.type}</span></span>
                                    </li>
                                    <li className="flex items-center py-3">
                                        <span>Status</span>
                                        <span className="ml-auto"><span
                                            className="bg-primary py-1 px-2 rounded text-white text-sm">{seller?.subscriptionDtls?.status || 'success'}</span></span>
                                    </li>
                                    <li className="flex items-center py-3">
                                        <span>Payment Status</span>
                                        <span
                                            className="ml-auto"><span>{seller?.subscriptionDtls?.paymentStatus}</span></span>
                                    </li>
                                    <li className="flex items-center py-3">
                                        <span>Start Date</span>
                                        <span
                                            className="ml-auto">{moment(seller?.subscriptionDtls?.startDate).format('LL')}</span>
                                    </li>

                                    <li className="flex items-center py-3">
                                        <span>End Date</span>
                                        <span
                                            className="ml-auto">{moment(seller?.subscriptionDtls?.endDate).format('LL')}</span>
                                    </li>
                                </ul>
                            </div>
                        }

                        <div className="my-4"></div>
                    </div>
                    <div className="w-full md:w-9/12 mx-2 h-64">
                        <div className="bg-white p-3 shadow-lg py-4 px-4 bg-white rounded-3xl">
                            <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                        <span clas="text-green-500">
                            <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                            </svg>
                        </span>
                                <span className="tracking-wide">About</span>
                            </div>
                            <div className="text-gray-700">
                                <div className="grid md:grid-cols-2 text-sm">
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Name</div>
                                        <div className="px-4 py-2">{seller?.name}</div>
                                    </div>
                                    {/*<div className="grid grid-cols-2">*/}
                                    {/*    <div className="px-4 py-2 font-semibold">Last Name</div>*/}
                                    {/*    <div className="px-4 py-2">Doe</div>*/}
                                    {/*</div>*/}
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Gender</div>
                                        <div className="px-4 py-2">{seller?.gender}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Contact No.</div>
                                        <div className="px-4 py-2">{seller?.contact?.mobileNo}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Current Address</div>
                                        <div
                                            className="px-4 py-2">{seller?.address?.addressLine1}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Permanant Address</div>
                                        <div
                                            className="px-4 py-2">{seller?.address?.addressLine1}</div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="px-4 py-2 font-semibold">Email.</div>
                                        <div className="px-4 py-2">
                                            <a className="text-blue-800"
                                               href="mailto:jane@example.com">{seller?.contact?.emailId}</a>
                                        </div>
                                    </div>
                                    {
                                        seller?.registrationNumber && <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Registration Number</div>
                                            <div className="px-4 py-2">{seller?.registrationNumber}</div>
                                        </div>
                                    }
                                    {
                                        seller?.gstIn && <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">GSTIN</div>
                                            <div className="px-4 py-2">{seller?.gstIn}</div>
                                        </div>
                                    }
                                    {
                                        seller?.yearOfEstablishment && <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Year Of Est</div>
                                            <div className="px-4 py-2">{seller?.yearOfEstablishment}</div>
                                        </div>
                                    }
                                    {
                                        seller?.isVerifiedSeller && <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Verified Seller</div>
                                            <div className="px-4 py-2">{seller?.isVerifiedSeller}</div>
                                        </div>
                                    }
                                    {
                                        seller?.sellerServices && <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Services</div>
                                            <div className="px-4 py-2">{seller?.sellerServices}</div>
                                        </div>
                                    }
                                </div>
                                <div className="grid md:grid-cols-1 text-sm">
                                    {
                                        seller?.sellerCategories && <div className="grid grid-cols-3">
                                            {
                                                seller?.sellerCategories.map((value)=> {
                                                    return (
                                                        <div >
                                                        <div style={{textAlign:'center'}}
                                                                 className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-primary border border-primary-700 ">
                                                        <div
                                                            className="text-xs font-normal leading-none flex-initial">{value?.categoryName}
                                                        </div>
                                                    </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/*<div className="px-4 py-2">{seller?.sellerCategories}</div>*/}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="my-4"></div>

                        {
                            sellerRatings.length>0 && <>
                            {
                                sellerRatings.map((rating)=>{
                                    return (
                                        <div className="mb-2 shadow-lg rounded-t-8xl rounded-b-5xl overflow-hidden"
                                             data-path="0.0.4">
                                            <div className="pt-3 pb-3 md:pb-1 px-4 md:px-16 bg-white bg-opacity-40"
                                                 data-path="0.0.4.0">
                                                <div className="flex flex-wrap items-center" data-path="0.0.4.0.0">
                                                    <img className="mr-6" src={"images/icons/user2.png"} alt=""
                                                         data-config-id="auto-img-2-1" data-path="0.0.4.0.0.0"/>
                                                    <h4 className="w-full md:w-auto text-xl font-heading font-medium"
                                                        data-config-id="auto-txt-30-1" data-path="0.0.4.0.0.1">{rating?.postBy}</h4>
                                                    <div
                                                        className="w-full md:w-px h-2 md:h-8 mx-8 bg-transparent md:bg-gray-200"
                                                        data-path="0.0.4.0.0.2"></div>
                                                    <span className="mr-4 text-xl font-heading font-medium"
                                                          data-config-id="auto-txt-31-1"
                                                          data-path="0.0.4.0.0.3">5.0</span>
                                                    {
                                                        <div className="flex justify-between mt-3">
                                                            <div className="flex-align-center gap-x-2">
                                                                <ReactStars
                                                                    size={16}
                                                                    isHalf={true}
                                                                    activeColor="#ffd700"
                                                                    value={rating?.rating}
                                                                    edit={false}
                                                                />
                                                                <div
                                                                    className="text-gray-600 ml-2 text-sm md:text-base mt-1">{sellerRatings?.length} reviews
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <div className="px-4 overflow-hidden md:px-16 pt-8 pb-12 bg-white"
                                                 data-path="0.0.4.1">
                                                <div className="flex flex-wrap" data-path="0.0.4.1.0">
                                                    <div className="w-full md:w-2/3 mb-6 md:mb-0"
                                                         data-path="0.0.4.1.0.0">
                                                        <p className="mb-8 max-w-2xl text-darkBlueGray-400 leading-loose"
                                                           data-config-id="auto-txt-37-1" data-path="0.0.4.1.0.0.0">{rating.description}</p>
                                                    </div>
                                                    <div className="w-full md:w-1/3 text-right" data-path="0.0.4.1.0.1">
                                                        <p className="mb-8 text-sm text-gray-300"
                                                           data-config-id="auto-txt-41-1"
                                                           data-path="0.0.4.1.0.1.0">{calculateTime(rating?.createdDate)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </>
                        }

                        {/*<div className="mb-2 shadow-lg rounded-t-8xl rounded-b-5xl overflow-hidden" data-path="0.0.4">*/}
                        {/*    <div className="pt-3 pb-3 md:pb-1 px-4 md:px-16 bg-white bg-opacity-40" data-path="0.0.4.0">*/}
                        {/*        <div className="flex flex-wrap items-center" data-path="0.0.4.0.0">*/}
                        {/*            <img className="mr-6" src={"images/icons/user2.png"} alt=""*/}
                        {/*                 data-config-id="auto-img-2-1" data-path="0.0.4.0.0.0"/>*/}
                        {/*            <h4 className="w-full md:w-auto text-xl font-heading font-medium"*/}
                        {/*                data-config-id="auto-txt-30-1" data-path="0.0.4.0.0.1">Faustina H. Fawn</h4>*/}
                        {/*            <div className="w-full md:w-px h-2 md:h-8 mx-8 bg-transparent md:bg-gray-200"*/}
                        {/*                 data-path="0.0.4.0.0.2"></div>*/}
                        {/*            <span className="mr-4 text-xl font-heading font-medium" data-config-id="auto-txt-31-1"*/}
                        {/*                  data-path="0.0.4.0.0.3">5.0</span>*/}
                        {/*            <div className="inline-flex" data-path="0.0.4.0.0.4">*/}
                        {/*                <a className="inline-block mr-1" href="#" data-path="0.0.4.0.0.4.0">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                        {/*                         xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-11-1"*/}
                        {/*                         data-path="0.0.4.0.0.4.0.0">*/}
                        {/*                        <path*/}
                        {/*                            d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"*/}
                        {/*                            fill="#FFCB00" data-path="0.0.4.0.0.4.0.0.0"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*                <a className="inline-block mr-1" href="#" data-path="0.0.4.0.0.4.1">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                        {/*                         xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-12-1"*/}
                        {/*                         data-path="0.0.4.0.0.4.1.0">*/}
                        {/*                        <path*/}
                        {/*                            d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"*/}
                        {/*                            fill="#FFCB00" data-path="0.0.4.0.0.4.1.0.0"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*                <a className="inline-block mr-1" href="#" data-path="0.0.4.0.0.4.2">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                        {/*                         xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-13-1"*/}
                        {/*                         data-path="0.0.4.0.0.4.2.0">*/}
                        {/*                        <path*/}
                        {/*                            d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"*/}
                        {/*                            fill="#FFCB00" data-path="0.0.4.0.0.4.2.0.0"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*                <a className="inline-block mr-1" href="#" data-path="0.0.4.0.0.4.3">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                        {/*                         xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-14-1"*/}
                        {/*                         data-path="0.0.4.0.0.4.3.0">*/}
                        {/*                        <path*/}
                        {/*                            d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"*/}
                        {/*                            fill="#FFCB00" data-path="0.0.4.0.0.4.3.0.0"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*                <a className="inline-block text-gray-200" href="#" data-path="0.0.4.0.0.4.4">*/}
                        {/*                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"*/}
                        {/*                         xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-15-1"*/}
                        {/*                         data-path="0.0.4.0.0.4.4.0">*/}
                        {/*                        <path*/}
                        {/*                            d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z"*/}
                        {/*                            fill="#FFCB00" data-path="0.0.4.0.0.4.4.0.0"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="px-4 overflow-hidden md:px-16 pt-8 pb-12 bg-white" data-path="0.0.4.1">*/}
                        {/*        <div className="flex flex-wrap" data-path="0.0.4.1.0">*/}
                        {/*            <div className="w-full md:w-2/3 mb-6 md:mb-0" data-path="0.0.4.1.0.0">*/}
                        {/*                <p className="mb-8 max-w-2xl text-darkBlueGray-400 leading-loose"*/}
                        {/*                   data-config-id="auto-txt-37-1" data-path="0.0.4.1.0.0.0">I haretra neque non mi*/}
                        {/*                    aliquam, finibus hart bibendum molestie. Vestibulum suscipit sagittis dignissim*/}
                        {/*                    mauris.</p>*/}
                        {/*                <div className="-mb-2" data-path="0.0.4.1.0.0.1">*/}
                        {/*                    <div className="inline-flex w-full md:w-auto md:mr-2 mb-2"*/}
                        {/*                         data-path="0.0.4.1.0.0.1.0">*/}
                        {/*                        <div*/}
                        {/*                            className="flex items-center h-12 pl-2 pr-6 bg-green-100 border-2 border-green-500 rounded-full"*/}
                        {/*                            data-path="0.0.4.1.0.0.1.0.0">*/}
                        {/*                            <div*/}
                        {/*                                className="flex mr-2 w-8 h-8 items-center justify-center bg-white rounded-full text-green-500"*/}
                        {/*                                data-path="0.0.4.1.0.0.1.0.0.0">*/}
                        {/*                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"*/}
                        {/*                                     xmlns="http://www.w3.org/2000/svg"*/}
                        {/*                                     data-config-id="auto-svg-16-1"*/}
                        {/*                                     data-path="0.0.4.1.0.0.1.0.0.0.0">*/}
                        {/*                                    <path*/}
                        {/*                                        d="M10.016 6.366H6.38V10.092H4.472V6.366H0.836V4.638H4.472V0.911999H6.38V4.638H10.016V6.366Z"*/}
                        {/*                                        fill="currentColor"*/}
                        {/*                                        data-path="0.0.4.1.0.0.1.0.0.0.0.0"></path>*/}
                        {/*                                </svg>*/}
                        {/*                            </div>*/}
                        {/*                            <span className="text-green-500 font-heading font-medium"*/}
                        {/*                                  data-config-id="auto-txt-38-1" data-path="0.0.4.1.0.0.1.0.0.1">battery life</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="inline-flex w-full md:w-auto md:mr-2 mb-2"*/}
                        {/*                         data-path="0.0.4.1.0.0.1.1">*/}
                        {/*                        <div*/}
                        {/*                            className="flex items-center h-12 pl-2 pr-6 bg-green-100 border-2 border-green-500 rounded-full"*/}
                        {/*                            data-path="0.0.4.1.0.0.1.1.0">*/}
                        {/*                            <div*/}
                        {/*                                className="flex mr-2 w-8 h-8 items-center justify-center bg-white rounded-full text-green-500"*/}
                        {/*                                data-path="0.0.4.1.0.0.1.1.0.0">*/}
                        {/*                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"*/}
                        {/*                                     xmlns="http://www.w3.org/2000/svg"*/}
                        {/*                                     data-config-id="auto-svg-17-1"*/}
                        {/*                                     data-path="0.0.4.1.0.0.1.1.0.0.0">*/}
                        {/*                                    <path*/}
                        {/*                                        d="M10.016 6.366H6.38V10.092H4.472V6.366H0.836V4.638H4.472V0.911999H6.38V4.638H10.016V6.366Z"*/}
                        {/*                                        fill="currentColor"*/}
                        {/*                                        data-path="0.0.4.1.0.0.1.1.0.0.0.0"></path>*/}
                        {/*                                </svg>*/}
                        {/*                            </div>*/}
                        {/*                            <span className="text-green-500 font-heading font-medium"*/}
                        {/*                                  data-config-id="auto-txt-39-1"*/}
                        {/*                                  data-path="0.0.4.1.0.0.1.1.0.1">functionality</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="inline-flex w-full md:w-auto mb-2" data-path="0.0.4.1.0.0.1.2">*/}
                        {/*                        <div*/}
                        {/*                            className="flex items-center h-12 pl-2 pr-6 bg-green-100 border-2 border-green-500 rounded-full"*/}
                        {/*                            data-path="0.0.4.1.0.0.1.2.0">*/}
                        {/*                            <div*/}
                        {/*                                className="flex mr-2 w-8 h-8 items-center justify-center bg-white rounded-full text-green-500"*/}
                        {/*                                data-path="0.0.4.1.0.0.1.2.0.0">*/}
                        {/*                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none"*/}
                        {/*                                     xmlns="http://www.w3.org/2000/svg"*/}
                        {/*                                     data-config-id="auto-svg-18-1"*/}
                        {/*                                     data-path="0.0.4.1.0.0.1.2.0.0.0">*/}
                        {/*                                    <path*/}
                        {/*                                        d="M10.016 6.366H6.38V10.092H4.472V6.366H0.836V4.638H4.472V0.911999H6.38V4.638H10.016V6.366Z"*/}
                        {/*                                        fill="currentColor"*/}
                        {/*                                        data-path="0.0.4.1.0.0.1.2.0.0.0.0"></path>*/}
                        {/*                                </svg>*/}
                        {/*                            </div>*/}
                        {/*                            <span className="text-green-500 font-heading font-medium"*/}
                        {/*                                  data-config-id="auto-txt-40-1" data-path="0.0.4.1.0.0.1.2.0.1">ease of use</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="w-full md:w-1/3 text-right" data-path="0.0.4.1.0.1">*/}
                        {/*                <p className="mb-8 text-sm text-gray-300" data-config-id="auto-txt-41-1"*/}
                        {/*                   data-path="0.0.4.1.0.1.0">Added 2 months ago</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="my-4"></div>
                    </div>
                </div>
            </div>
        </div>
    }

    </>
  );
};

export default ProfileDetail;
