import { useSelector } from "react-redux";
import { dataStore } from "../../../features/dataSlice";
import SingleProductCardFullWidth from "./SingleProductCardFullWidth";
import {useSellersContext} from "../../../context/sellers_context";
import {useFilterContext} from "../../../context/filter_context";
const PropertyFullWidth = () => {
  // const { currentDataItems } = useSelector(dataStore);
  // const {sellers} =useSellersContext();
  const { filtered_sellers } = useFilterContext();
  return (
    <div>
      {filtered_sellers.length>0?filtered_sellers?.map((property) => (
        <SingleProductCardFullWidth key={property.id} {...property} />
      )): <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>No Sellers Found</div>}
    </div>
  );
};

export default PropertyFullWidth;
