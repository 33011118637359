import { BiPlay } from "react-icons/bi";
import React, {useState} from "react";
import {createPortal} from "react-dom";
import {Modal} from "../../common/Modal";
import './signupmodal.css';

const Hero = () => {

  return (
      <>
        <div className="relative flex flex-wrap min-h-screen gap-2 md:-mt-10 hero-bg">
          <div className="flex-1 basis-[20rem] !text-slate-100 px-[3%] md:px-[6%] mt-20">
            <h1 className="text-4xl font-bold capitalize lg:text-5xl">
              One Stop Solution for all <br/> Construction needs
            </h1>
            <div className="pl-3 mt-5 border-l-4 border-primary">
              <p>
                Build Your Dream House By Searching Our Popular Categories
              </p>
            </div>
            <div className="mt-6 flex-align-center gap-x-3">
              <button className="btn btn-primary">Get Hired</button>
              <button className="icon-box !opacity-100 relative before:w-full before:absolute before:h-full before:rounded-full before:bg-[#0176b4] hover:!bg-[#003b5b] before:animate-ping bg-[#035581]">
                <BiPlay/>
              </button>
            </div>
          </div>
        </div>
      </>
  );
};

export default Hero;
