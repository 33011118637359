export const price = [
  {
    plan: "Start",
    price: "free",
    ptext: "per 3 months",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: <span>Search Ranking <span className="font-semibold">Lite</span></span>,
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: "Direct Quote",
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: "CB Verified Seller",
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: "Personalized Corporate Page",
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: "Banner Advertisement",
      },
      {
        change: "color",
        icon: <i className='fa-solid fa-x'></i>,
        text: <span>CB Help Desk <span className="font-semibold">Email Support</span></span>
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Contact Details of Buyer",
      }
    ],
  },
      {
        best: "Most popular",
        plan: "Pro",
        price: "500",
        ptext: "per 3 months",
        list: [
          {
            icon: <i className='fa-solid fa-check'></i>,
            text: <span>Search Ranking <span className="font-semibold">Super</span></span>,
          },
          {
            icon: <i className='fa-solid fa-check'></i>,
            text: "Direct Quote",
          },
          {
            icon: <i className='fa-solid fa-check'></i>,
            text: "CB Verified Seller",
          },
          {
            change: "color",
            icon: <i className='fa-solid fa-x'></i>,
            text: "Personalized Corporate Page",
          },
          {
            icon: <i className='fa-solid fa-check'></i>,
            text:< span > Banner Advertisement < span
            className = "font-semibold" >20 Days< /span></span >,
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: <span>CB Help Desk <span className="font-semibold">Standard</span></span>
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Contact Details of Buyer",
      }
    ],
  },
  {
    plan: "Business",
    price: "700",
    ptext: "per 3 months",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: <span>Search Ranking <span className="font-semibold">Deluxe</span></span>,
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Direct Quote",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "CB Verified Seller",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Personalized Corporate Page",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text:< span > Banner Advertisement < span
            className = "font-semibold" > 30
            Days < /span></span >,
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: <span>CB Help Desk <span className="font-semibold">Dedicated</span></span>
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Contact Details of Buyer",
      }
    ],
  },
]
