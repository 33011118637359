import React, {useContext, useEffect, useState} from "react";
import Select from "react-tailwindcss-select";
import StepperContext from "../../context/StepperContext";
import {City, Country, State} from "country-state-city";
import './AddressInfo.css';
import LocationService from "../../services/location.service";

const AddressInfo = () => {
    const {yourInfo,setYourInfo, countryData, setCountryData,
        stateData, setStateData,
        cityData, setCityData,
        country, setCountry,
        state, setState,
        city, setCity,addressRequired, setAddressRequired,
        countryRequired, setCountryRequired,
        stateRequired, setStateRequired,
        cityRequired, setCityRequired,
        zipRequired, setZipRequired}=useContext(StepperContext);
    useEffect(() => {
        const options=[];
        const data= Country.getAllCountries();
        if(data.length>0){
            data.map(data=>options.push({label:data.name,value:data.isoCode}))
            options && setCountryData(options);
        }
    }, []);
    useEffect(() => {
        const options=[];
        const data=State.getStatesOfCountry(country?.value);
        if(data.length>0){
            data.map(data=>options.push({label:data.name,value:data.isoCode}))
            options && setStateData(options);
        }
    }, [country]);

    useEffect(() => {
        const options=[];
        const data= City.getCitiesOfState(country?.value, state?.value);
        if(data.length>0){
            data.map(data=>options.push({label:data.name,value:data.name}))
            options && setCityData(options);
        }
    }, [state]);
    const changeYourInfo = (event) => {
        setYourInfo((prevInfo) => {
            return { ...prevInfo, [event.target.name]: event.target.value };
        });
        if(event.target.name === "country"){
            setCountry(event.target.value);
        }
        if(event.target.name === "state"){
            setState(event.target.value);
        }
        if(event.target.name === "city"){
            setCity(event.target.value);
        }
    };
    useEffect(()=>{
        if(yourInfo["address"] === ""){
            setAddressRequired("block");
        }else {
            setAddressRequired("hidden");
        }
        if(country === null || country === undefined){
            setCountryRequired("block");
        }else {
            setCountryRequired("hidden");
        }
        if(state === null || state === undefined){
            setStateRequired("block");
        }else {
            setStateRequired("hidden");
        }
        if(city === null || city === undefined){
            setCityRequired("block");
        }else {
            setCityRequired("hidden");
        }
        if(yourInfo["zip"] === "" || yourInfo["zip"] === 0){
            setZipRequired("block");
        }else {
            setZipRequired("hidden");
        }

    },[yourInfo,country,state,city])
    function onClickLocateIcon() {
        LocationService.getLocationInfo().then((response)=>{
               setCountry({
                value: response.address["ISO3166-2-lvl4"].split("-")[0],
                label: response.address.country,
                isSelected: true});
               setState({
                   value: response.address["ISO3166-2-lvl4"].split("-")[1] ==="TS"?"TG":response.address["ISO3166-2-lvl4"].split("-")[1],
                   label: response.address.state,
                   isSelected: true});
                setYourInfo((prevInfo) => {
                    return { ...prevInfo, ["address"]: response.display_name,["zip"]:response.address.postcode,["country"]:response.address["ISO3166-2-lvl4"].split("-")[0],["state"]:response.address["ISO3166-2-lvl4"].split("-")[1] ==="TS"?"TG":response.address["ISO3166-2-lvl4"].split("-")[1]};
                });
        }
        )
    }

    return(
        <>
            <h1 className="mt-10 text-3xl font-[800] mb-2 text-primary-marineBlue">
                Address info
            </h1>
            <p className="text-neutral-coolGray mb-6">
                Please provide your county, state, city.
            </p>
            <form className="flex flex-col">
                <div className="form-wrapper flex flex-col relative">
                    <>
                        <label className="text-primary-marineBlue font-[500] mb-2">
                            Address Line
                        </label>
                        <input
                            title="Click On Locate icon to auto populate address"
                            id="address"
                            onChange={changeYourInfo}
                            name="address"
                            className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${addressRequired ==='block' ? '!border-[#ed3548]' :'border-[#d6d9e6]'} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                            type="text"
                            placeholder="Enter Address"
                            value={yourInfo ? yourInfo["address"] : ""}
                        />
                        <img src="/images/signup/locate.png" id="locateButton" className="w-3" alt="Locate Icon"
                             onClick={onClickLocateIcon}/>

                    </>
                    <p
                        className={`${addressRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                    >
                        This field is required
                    </p>
                    <label className="text-primary-marineBlue font-[500] mb-2">
                        Country
                    </label>
                    <Select
                        isSearchable={true}
                        isClearable={true}
                        isMultiple={false}
                        value={country}
                        onChange={(value) => changeYourInfo({target: {value: value, name: 'country'}})}
                        options={countryData}
                        primaryColor='orange'
                        className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${countryRequired ==='block' ? '!border-[#ed3548]' : 'border-[#d6d9e6]'} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                    />
                    <p
                        className={`${countryRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                    >
                        This field is required
                    </p>
                    <label className="text-primary-marineBlue font-[500] mb-2">
                        State
                    </label>
                    <Select
                        isSearchable={true}
                        isMultiple={false}
                        isClearable={true}
                        value={state}
                        onChange={(value) => changeYourInfo({target: {value: value, name: 'state'}})}
                        options={stateData}
                        primaryColor='orange'
                        className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${stateRequired ==='block' ? '!border-[#ed3548]' : 'border-[#d6d9e6]'} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                    />
                    <p
                        className={`${stateRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                    >
                        This field is required
                    </p>
                    <label className="text-primary-marineBlue font-[500] mb-2">
                        City
                    </label>
                    <Select
                        isSearchable={true}
                        isMultiple={false}
                        isClearable={true}
                        value={city}
                        onChange={(value) => changeYourInfo({target: {value: value, name: 'city'}})}
                        options={cityData}
                        primaryColor='orange'
                        className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${cityRequired ==='block' ? '!border-[#ed3548]' : 'border-[#d6d9e6]'} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                    />
                    <p
                        className={`${cityRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                    >
                        This field is required
                    </p>
                    <label className="text-primary-marineBlue font-[500] mb-2">
                        Zip Code
                    </label>
                    <input
                        onChange={changeYourInfo}
                        name="zip"
                        className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${zipRequired ==='block' ? '!border-[#ed3548]' : 'border-[#d6d9e6]'} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                        type="number"
                        placeholder="Enter Zip code"
                        value={yourInfo ? yourInfo["zip"] : ""}
                        required={true}
                    />
                    <p
                        className={`${zipRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                    >
                        This field is required
                    </p>
                </div>
            </form>
        </>
    )
}
export default AddressInfo;