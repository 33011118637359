import {
  Filters,
  GetInTouch,
  Hero,
  Testimonial,
  Category, UserLocation,
} from "../components/home/home-3";
import {useEffect, useState} from "react";
import {useSellersContext} from "../context/sellers_context";

const HomeThree = () => {
    const { categoryData } = useSellersContext();
  return (
    <div className="md:pt-[5.2rem] pt-10">
      <Hero />
      <Filters categoryData={categoryData}/>
      <div className="mt-10 px-[3%] md:px-[6%]">
        <Category categoryData={categoryData}/>
        {/* <AboutUs /> */}
        {/* <WhatWeDo /> */}
        {/* <Featured /> */}
        {/* <Categories /> */}
        <GetInTouch />
        {/* <Team /> */}
        <Testimonial />
        {/* <Feeds /> */}
        {/* <Brands /> */}
      </div>
    </div>
  );
};

export default HomeThree;
