export const GET_SELLERS_BEGIN = 'GET_SELLERS_BEGIN'
export const GET_SELLERS_SUCCESS = 'GET_SELLERS_SUCCESS'
export const GET_SELLERS_ERROR = 'GET_SELLERS_ERROR'
export const GET_CATEGORY_BEGIN = 'GET_CATEGORY_BEGIN'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'
export const GET_SINGLE_SELLER_BEGIN = 'GET_SINGLE_SELLER_BEGIN'
export const GET_SINGLE_SELLER_SUCCESS = 'GET_SINGLE_SELLER_SUCCESS'
export const GET_SINGLE_SELLER_ERROR = 'GET_SINGLE_SELLER_ERROR'
export const LOAD_SELLERS = 'LOAD_SELLERS'
export const SET_GRIDVIEW = 'SET_GRIDVIEW'
export const SET_LISTVIEW = 'SET_LISTVIEW'
export const UPDATE_SORT = 'UPDATE_SORT'
export const SORT_SELLERS = 'SORT_SELLERS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const FILTER_SELLERS = 'FILTER_SELLERS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA'
export const SET_CITY_DATA = 'SET_CITY_DATA'
export const SET_STATE_DATA = 'SET_STATE_DATA'
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA'
