/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FiDelete, FiMoon, FiSun } from "react-icons/fi";
import { BiSearch, BiMenu, BiUser, BiBuildingHouse } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

import {
  closeDropdown,
  closeSidebar,
  openSidebar,
  toggleDarkMode,
  uiStore,
} from "../../features/uiSlice";
import { navLinks } from "../../data/navLinks";
import SingleLink from "./SingleLink";
import {createPortal} from "react-dom";
import {Modal} from "./Modal";
import OtpService from "../../services/otp.service";
import {useLoading} from "../../context/LoadingContext";
import SellerService from "../../services/seller.service";
import UserService from "../../services/user.service";
import {Bounce, toast} from "react-toastify";

const Navbar = () => {
  const rootDoc = document.querySelector(":root");
  const {setLoading} = useLoading();
  const {darkMode, isSidebarOpen} = useSelector(uiStore);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOtpOpen, setModalOtpOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [toggle, setToggle] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpResponse, setOtpResponse] = useState();
  const [isCheckedValue, setIsCheckedValue] = useState("Customer");
  const [redBorder, setRedBorder] = useState("border-[#d6d9e6]");
  const [displayRequired, setDisplayRequired] = useState("hidden");

  const handleButtonClick = () => {
    if(mobileNumber && mobileNumber!=="" && mobileNumber.length === 10){
      setLoading(true);
      UserService.loginWithOtp({mobileNumber:mobileNumber,type:isCheckedValue}).then((response)=>{
        if(response!==null && response.data!==null && response.data.success){
          setOtpResponse(response.data.response);
          setModalOpen(false);
          setModalOtpOpen(true);
          setLoading(false);
        }
      }).catch(err=>setLoading(false));
    }else {
      setDisplayRequired("block");
      setRedBorder("!border-[#ed3548]");
    }
  };
  const handleCloseModel = () => {
    setModalOpen(false);
  };

  const handleOtpCloseModel = () => {
    setModalOtpOpen(false);
  };

  const handleOtpButtonClick = () => {
    if(otp && otp!== ""){
      setLoading(true);
      setModalOtpOpen(false);
      UserService.authorizeOtp({type:isCheckedValue,mobileNumber:mobileNumber,refId:otpResponse.otpRefId,newOtp:otp}).then((response)=>{
        if(response!==null && response.data!==null && response.data.response){
          // setModalOpen(false);
          setModalOtpOpen(false);
          toast.success('Otp Validated Successfully ', {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            theme: "light",
            transition: Bounce,
          });
          localStorage.setItem("mobileNumber", JSON.stringify(mobileNumber));
          if(isCheckedValue === "Seller"){
            SellerService.getBySellerMdn(mobileNumber).then(response=>{
              if(response!== null && (response.data!=="")){
                setLoading(false);
                sessionStorage.setItem("userInfo", JSON.stringify(response.data));
                navigate("/profiledetail/"+response.data?.id);
              } else {
                setLoading(false);
                navigate("/sellersignup");
              }
            }).catch(error=>{console.log(error)});

          }
        }else {
          toast.error('Invalid OTP Entered ', {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            theme: "light",
            transition: Bounce,
          });
          setLoading(false);
        }
      }).catch(err=>setLoading(false));
    }else {
      setDisplayRequired("block");
      setRedBorder("!border-[#ed3548]");
    }
  };

  const onOptionChange = e => {
    setIsCheckedValue(e.target.value)
  }
  const headerContent = <div className="wrapper">
    <div className="slide-controls">
      <input type="radio" name="slide" id="login" value="Customer" checked={isCheckedValue==="Customer"}  onChange={onOptionChange}/>
      <input type="radio" name="slide" id="signup" value="Seller" checked={isCheckedValue==="Seller"}  onChange={onOptionChange}/>
      <label htmlFor="login" className="slide login">Customer</label>
      <label htmlFor="signup" className="slide signup">Seller</label>
      <div className="slider-tab"></div>
    </div>
  </div>

  const footerContent = <button className="btn btn-primary" onClick={handleButtonClick}>Login with OTP</button>;

  const footerOtpContent = <button className="btn btn-primary"  onClick={handleOtpButtonClick}>Submit</button>;

  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  // Store darkmode value to localStorage;
  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
    localStorage.setItem("Martvilla-theme-mode", JSON.stringify(darkMode));
  }, [darkMode]);

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  return (
      <>
        <div
            className="navbar h-[45px] fixed w-full z-20 top-0 left-0 px-[2%]  md:px-[6%] flex-center-between py-[0.35rem] bg-white/60 border-b backdrop-blur-sm dark:border-dark dark:bg-card-dark/60"
            onMouseOver={handleClose}
        >
          <Link to="/" className="flex-shrink-0 flex-center-between gap-x-1">
            <img src="/logo3.png" className="text-3xl text-primary" width="55" height="10" alt="logo"/>
            <h1 className="hidden md:block">Gumpu Mestri</h1>
          </Link>

          <div className="flex-align-center gap-x-4">
            {/*-------------------------------------- Desktop Menu------------------------------------- */}
            <ul
                className={`hidden md:flex-align-center`}
            >
              {navLinks.map((link) => (
                  <SingleLink {...link} key={link.id}/>
              ))}
            </ul>

            {/*---------------------------------------- Mobile Menu------------------------------------- */}
            <div
                className={`lg:hidden mobile-modal fixed w-screen h-screen top-0 left-0 bg-black/50 z-50 opacity-0 pointer-events-none transition-a  ${
                    isSidebarOpen && "open"
                }`}
                onClick={handleCloseSidebar}
            >
              <ul
                  className={`mobile-dialog overflow-auto absolute flex flex-col space-y-4 p-3 bg-white dark:bg-card-dark h-screen max-w-[300px] w-full -translate-x-[500px] transition-a ${
                      isSidebarOpen && "open"
                  }`}
              >
                <div className="border-b flex-center-between dark:border-slate-800">
                  <p className="uppercase">menu</p>
                  <div
                      className="icon-box md:hidden"
                      onClick={() => dispatch(closeSidebar())}
                  >
                    <FiDelete/>
                  </div>
                </div>
                {navLinks?.map(({id, linkText, url, subLinks}) => (
                    <ul key={id}>
                      <NavLink
                          to={url}
                          end
                          className="w-fit before:!hidden"
                          onClick={() => dispatch(closeSidebar())}
                      >
                        {linkText}
                      </NavLink>
                      {subLinks?.map(({id, linkText, url}) => (
                          <ul key={id} className="mt-2">
                            <NavLink
                                to={url}
                                end
                                className="relative ml-8 text-sm before:hidden w-fit after:absolute after:w-2 after:h-2 after:rounded-full after:border-2 after:top-1/2 after:-translate-y-1/2 after:-left-4 dark:after:opacity-50"
                                onClick={() => dispatch(closeSidebar())}
                            >
                              {linkText}
                            </NavLink>
                          </ul>
                      ))}
                    </ul>
                ))}
              </ul>
            </div>

            <div className="space-x-2 flex-align-center">
              {/*----------------------------- search Bar----------------------------------------------------- */}
              {/*<form onSubmit={handleSubmit}>*/}
              {/*  <div*/}
              {/*    className={`flex-align-center relative h-9 w-9 transition-a  border-slate-300 dark:border-dark rounded-full ${*/}
              {/*      showSearchBar &&*/}
              {/*      "!w-[150px] md:!w-[200px] border bg-transparent text-inherit"*/}
              {/*    }`}*/}
              {/*  >*/}
              {/*    <input*/}
              {/*      type="search"*/}
              {/*      className={`outline-none border-none h-0 w-0 bg-transparent ${*/}
              {/*        showSearchBar && "!w-full !h-full px-4"*/}
              {/*      }`}*/}
              {/*      placeholder="search..."*/}
              {/*      value={searchTerm}*/}
              {/*      onChange={(e) => setSearchTerm(e.target.value)}*/}
              {/*    />*/}
              {/*    <span*/}
              {/*      className={`grid flex-shrink-0 rounded-full w-9 h-9 place-items-center text-white bg-primary sm:cursor-pointer ${*/}
              {/*        showSearchBar &&*/}
              {/*        "bg-transparent hover:bg-slate-100 text-inherit sm:cursor-pointer dark:hover:bg-hover-color-dark"*/}
              {/*      }`}*/}
              {/*      onClick={() => setShowSearchBar(!showSearchBar)}*/}
              {/*    >*/}
              {/*      <BiSearch className="text-muted" />*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</form>*/}

              {/*----------------------------- Dark mode toggle-------------------------------------------------- */}
              <div
                  className="bg-white shadow-md icon-box dark:bg-dark-light hover:shadow-lg hover:bg-transparent"
                  onClick={handleDarkMode}
              >
                {darkMode ? <FiSun/> : <FiMoon/>}
              </div>
              {/*----------------------------- Profile Icon-------------------------------------------------- */}
              <div className="shadow-md dark:bg-dark-light hover:shadow-lg hover:bg-transparent">
                {/*<BiUser />*/}
                <button className="btn btn-primary" onClick={() => {
                  setDisplayRequired("hidden");
                  setRedBorder("border-[#d6d9e6]");
                  setMobileNumber("");
                  setModalOpen(true)}}>Login/Signup</button>
              </div>
              {/*------------------------------- Mobile Menu Toogle------------------------- */}
              <div
                  className="icon-box md:hidden"
                  onClick={() => dispatch(openSidebar())}
              >
                <BiMenu/>
              </div>
            </div>
          </div>
        </div>
        {modalOpen &&
            createPortal(
                <Modal
                    closeModal={handleCloseModel}
                    onSubmit={handleButtonClick}
                    onCancel={handleCloseModel}
                    headerContent={headerContent}
                    footerContent={footerContent}
                >
                  <label className="text-primary-marineBlue font-[500] mb-2">
                    Mobile Number
                  </label>
                  <input
                      onChange={(event) =>{
                        if(event.target.value!== "") {
                          setDisplayRequired("hidden");
                          setRedBorder("border-[#d6d9e6]");
                        }
                        setMobileNumber(event.target.value)}
                      }
                      name="mobilenumber"
                      className={`font-medium w-full mt-1 p-2 pl-3 rounded-full rounded-lg border ${redBorder} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                      type="number"
                      placeholder="Enter Mobile Number"
                      value={mobileNumber}
                      pattern="^[0-9]*$"
                  />
                  <p
                      className={`${displayRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                  >
                    This field is required
                  </p>
                </Modal>,
                document.body
            )}
        {modalOtpOpen &&
            createPortal(
                <Modal
                    closeModal={handleOtpCloseModel}
                    onSubmit={handleOtpButtonClick}
                    onCancel={handleOtpCloseModel}
                    footerContent={footerOtpContent}
                    headerContent="OTP"
                >
                  <label className="text-primary-marineBlue font-[500] mb-2">
                    OTP
                  </label>
                  <input
                      name="otp"
                      className={`font-medium w-full mt-1 p-2 pl-3 rounded-full rounded-lg border ${redBorder} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(event) => {
                        if (event.target.value !== "") {
                          setDisplayRequired("hidden");
                          setRedBorder("border-[#d6d9e6]");
                        }
                        setOtp(event.target.value)
                      }
                      }
                  />
                  <p
                      className={`${displayRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
                  >
                    This field is required
                  </p>
                </Modal>,
                document.body
            )}
      </>
  );
};

export default Navbar;
