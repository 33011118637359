import axios from "axios";

export default class CategoryService {

    static API_URL = String(process.env.REACT_APP_SERVICE_URL);
    static API_PREFIX_URL = String(process.env.REACT_APP_CATEGORY_PREFIX);
    static URL= CategoryService.API_URL.concat(CategoryService.API_PREFIX_URL);

    static getAllCategories = () => {
        return axios.get(CategoryService.URL + '/getAll',{
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
}
