import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import FormField from "../common/FormField";
import {MainProfileForm} from "./MainProfileForm";
import {useSellersContext} from "../../context/sellers_context";
import StepperContext from "../../context/StepperContext";

const PersonalInfo = ({ yourInfo, onChangeYourInfo, isEmpty }) => {
  const {categoryData} = useSellersContext();
  const {formFields, setFormFields} = useContext(StepperContext);
  useEffect(() => {
    if(categoryData.length>0){
      const options=[];
      categoryData.map(data=> options.push({label:data.name,value:data.id}));
      let newState = [...formFields];
      newState[3].options = options;
      setFormFields(newState);
    }
  }, [categoryData]);
  const onEventChange = (e,index) => {
    let newState = [...formFields];
    if(index!==3){
      newState[index].isValid = e.target.value !== "";
      setFormFields(newState);
      onChangeYourInfo(e);
    }else {
      if(e!==null && e.length>0){
        newState[index].isValid=true;
      }else
        newState[index].isValid=false;
      setFormFields(newState);
      onChangeYourInfo({target:{name:'category',value:e}});
    }
  }

  return (
     <>

        <h1 className="mt-10 text-3xl font-[800] mb-2 text-primary-marineBlue">
          Personal info
        </h1>
       <MainProfileForm />
        <p className="text-neutral-coolGray mb-6">
          Please provide your name, email address, and phone number.
        </p>
        <form className="flex flex-col">
          <div className="form-wrapper flex flex-col relative">
            {formFields.map((formField,index) => (
                <FormField
                    onChangeYourInfo={(e)=>onEventChange(e,index)}
                    key={formField.id}
                    name={formField.name}
                    label={formField.label}
                    type={formField.type}
                    placeholder={formField.placeholder}
                    value={yourInfo?yourInfo[formField.name]:""}
                    isValid={formField.isValid}
                    options={formField.options}
                    isMultiple={formField.isMultiple}
                    disabled={formField.disabled}
                />
            ))}
          </div>
        </form>
       </>
  );
};

export default PersonalInfo;
