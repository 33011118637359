export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/"
  },
  {
    id: 2,
    linkText: "Pricing",
    url: "/pricing",
  },
  {
    id: 3,
    linkText: "Contact",
    url: "/contact",
  }
];
