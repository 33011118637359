import { BiBriefcase, BiBuildings, BiMap, BiMoney } from "react-icons/bi";
import Select from 'react-select';
import {City} from "country-state-city";
import {useFilterContext} from "../../../context/filter_context";
import {useState} from "react";
import { useNavigate } from "react-router-dom";
const Filters = (props) => {
    const navigate=useNavigate();
    const [city,setCity]=useState();
    const [category,setCategory]=useState("");
    const {updateFilters}=useFilterContext();

    const cityData=City.getCitiesOfState("IN", "TG").map((city)=>{
        return {value:city.name,label:city.name}
    })
    const onSearch = () => {
        const data=props.categoryData.filter(cat=>cat.name===category);
        if(data && data.length>0){
            updateFilters("IN","country");
            updateFilters("TG","state");
            updateFilters(city,"city");
            updateFilters(data[0].id,"category");
            setTimeout(()=>{
                navigate("/viewById/"+data[0].id);
            },200)

        }

    }
  return (
    <div className="sm:max-w-[80%] w-[95%] mx-auto relative -mt-10 sm:-mt-32">
      <div className="bg-secondary dark:bg-black/30  sm:!bg-black/30 backdrop-blur-sm p-4 border border-slate-600 rounded-lg text-slate-100">
        <div className="flex-col mt-4 gap-x-4 flex-center-between gap-y-4 md:gap-y-0 md:flex-row ">
          <div className="flex-col flex-1 w-full flex-align-center gap-x-4 md:w-fit sm:flex-row gap-y-4 sm:gap-y-0">
            <div className="flex-1 w-full p-2 rounded-lg md:w-fit  md:!bg-transparent">
              <h1 className="font-bold">Location</h1>
              <div className="flex-align-center gap-x-2">
                <BiMap />
                {/*<input*/}
                {/*  type="text"*/}
                {/*  className="w-full bg-transparent border-0 outline-none"*/}
                {/*  placeholder="Enter location"*/}
                {/*/>*/}
                {/*<AutoComplete data={autoCompleteData} />*/}
                  <Select
                      className="basic-single w-full bg-transparent border-0 outline-none"
                      classNamePrefix="react-select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      name="color"
                      options={cityData}
                      onChange={(e)=>{
                          setCity(e.value);
                      }}
                      placeholder="Enter city name"
                      styles={{
                          control: (baseStyles, state) => ({
                              ...baseStyles,
                             background:"transparent", border:'0',
                              color:"white"
                          }),
                      }}
                  />
              </div>
            </div>
            <div className="h-10 w-[1px] hidden md:block "></div>
            <div className="flex-1 w-full p-2 rounded-lg md:w-fit  md:!bg-transparent">
              <h1 className="font-bold">Category</h1>
              <div className="flex-align-center gap-x-2">
                <BiBuildings />
                  <select
                      name=""
                      id=""
                      className="w-full bg-transparent border-0 outline-none text-slate-500"
                      onChange={e => setCategory(e.target.value)}
                  >
                      <option value="">Select category</option>
                      {/*<option value="condors">Condors</option>*/}
                      {/*<option value="offfice buildings">Offfice Buildings</option>*/}
                      {/*<option value="apartments">Apartments</option>*/}
                      {/*<option value="mansions">Mansions</option>*/}
                      {/*<option value="real estate">Real Estate</option>*/}
                      {/*<option value="penthouse">Penthouse</option>*/}
                      {/*<option value="living room">Living Room</option>*/}
                      {
                          props.categoryData?.length > 0 && props.categoryData?.map((element, index) => {
                              return (
                                  <option key={index} value={element.name}>{element.name}</option>
                              );
                          })
                      }
                  </select>
              </div>
            </div>
          </div>
          <button className="w-full btn btn-primary md:w-fit" type="submit" onClick={onSearch}>search</button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
