import React, {useState} from "react";
import SellerService from "../../../services/seller.service";
import {Bounce, toast} from "react-toastify";

const EnquiryForm = ({categoryName,type}) => {
    const [name,setName]=useState("");
    const [mobileNumber,setMobileNumber] = useState("");
    const list=['Bore Well','Centring','Column Boxes','Concrete Roller','Concrete Mixture','UPVC Windows','Hardware Grills']

    const getText = () => {

        if(list.includes(categoryName)) {
            return <span>Get the best deal for <h1 className="font-semibold text-secondary">{categoryName}</h1></span>
        }else {
            return <span>Get the List of Top <h1 className="font-semibold text-secondary">{categoryName}</h1></span>
        }
    }
    const onSubmit = () => {
        const payload={};
        payload.name = name;
        payload.mobileNo = mobileNumber;
        SellerService.enquirySeller(payload).then((response)=>{
            if(response?.data!==null){
                setName("");
                setMobileNumber("");
                toast.success('Enquiry Submitted Successfully ', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    theme: "light",
                    transition: Bounce,
                });
            }else {
                toast.error('Error On Enquiry Submission ', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    theme: "light",
                    transition: Bounce,
                });
            }
        }).catch((err)=> console.log(err))
    }
    const getClassNames = () => {
      if(type === "grid"){
          return "grid grid-cols-1 gap-10 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4"
      }else
          return "flex flex-col justify-around sm:justify-between"
    }
    return (
        <div className={`${getClassNames()} p-3 border dark:border-dark `} style={{gap: '10px'}}>
            <div>{getText()}</div>
            <div>
                <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="border outline-none bg-transparent dark:border-dark px-3 py-[0.35rem] w-full"
                    placeholder="Enter Name*"
                />
            </div>
            <div>
                <input
                    type="text"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="border outline-none bg-transparent dark:border-dark px-3 py-[0.35rem] w-full"
                    placeholder="Enter Mobile Number*"
                />
            </div>
            <div><button className="btn w-full bg-secondary text-slate-200 !rounded-none" onClick={onSubmit}>
                Submit Enquiry
            </button></div>
        </div>
    )
}
export default EnquiryForm;