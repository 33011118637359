const Map = () => {
  return (
    <div>
      <img
        src="/images/office-map.png"
        alt=""
        className="w-full h-[200px] object-cover"
      />
    </div>
  );
};

export default Map;
