const Loader = () => {
  return (
      <div className="w-full h-full fixed top-0 left-0 bg-black opacity-75 z-50">
          <div className="flex justify-center items-center mt-[50vh]">
              <div className="fas fa-circle-notch fa-spin fa-2x text-primary"></div>
          </div>
      </div>
      // <div className="fixed top-0 left-0 z-50 w-full h-full flex-center-center bg-main-bg dark:bg-main-dark">
      //   <div className="loader"></div>
      // </div>
      // <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
      //     <div className="flex items-center">
      //         <span className="text-2xl mr-4">Loading</span>
      //         <svg className="animate-spin h-8 w-8 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none"
      //              viewBox="0 0 24 24">
      //             <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      //             <path className="opacity-75" fill="currentColor"
      //                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
      //             </path>
      //         </svg>
      //     </div>
      // </div>
  );
};

export default Loader;
