import axios from "axios";

export default class ElasticService {

    static API_URL = String(process.env.REACT_APP_SERVICE_URL);
    static API_PREFIX_URL = String(process.env.REACT_APP_ELASTIC_PREFIX);
    static URL= ElasticService.API_URL.concat(ElasticService.API_PREFIX_URL);

    static fetchResults = (request) => {
        return axios.post(ElasticService.URL + '/fetchSearchResults',request,{
            headers: {
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
}
