import {useEffect, useState} from "react";
import React, { useParams } from "react-router-dom";
import { FiDelete } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  AdvancedSearch,
  CTA,
  HeadeFilters,
  Pagination,
  PriceRange,
  PropertyFullWidth,
  SocialIcons,
  Type,
} from "../components/common/page-componets";
import { PropertyList } from "../components/property";
import { property } from "../data/dummyData";
import { closeFilterMenu, uiStore } from "../features/uiSlice";
import CategoryService from "../services/category.service";
import ElasticService from "../services/elastic.service";
import {useFilterContext} from "../context/filter_context";
import {useSellersContext} from "../context/sellers_context";
import EnquiryForm from "../components/common/page-componets/EnquiryForm";

const PropertySix = (params) => {
  const { id } = useParams();
  const {fetchSellers,categoryData} = useSellersContext();
  const {sellers} =useSellersContext();
  const filtersData  = useFilterContext();
  const { isFilterMenuOpen } = useSelector(uiStore);
  const dispatch = useDispatch();
  const [categoryName,setCategoryName] = useState();
  const handleCloseFiltermenu = (e) => {
    if (e.target.classList.contains("filter-modal"))
      dispatch(closeFilterMenu());
  };
  useEffect(() => {
    if (id !== null) {
      if (id!== undefined) {
        if(categoryData.length>0){
          setCategoryName(categoryData?.filter(cat=>cat.id===id)[0]?.name);
        }
          fetchSellers(filtersData);
      }
    }
  }, [id]);
  const { grid_view,setGridView } = useFilterContext();

  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <HeadeFilters layout={grid_view} setLayout={setGridView} />
      <div className="grid mt-5 md:grid-cols-4 gap-x-14">
        <div className="top-0 mt-5 md:col-span-3 md:mt-0 h-fit md:sticky">
          {
            categoryName && <EnquiryForm categoryName={categoryName} type="grid"/>
          }
          {grid_view === "grid" ? <PropertyList /> : <PropertyFullWidth />}
          <Pagination itemsPerPage={6} pageData={sellers} />
        </div>
        <div className="top-0 row-start-3  md:col-span-1 md:row-start-auto h-fit md:sticky">
          <div
            className={`filter-modal ${isFilterMenuOpen && "open"}`}
            onClick={handleCloseFiltermenu}
          >
            <div className={`filter-dialog ${isFilterMenuOpen && "open"}`}>
              <div className="border-b flex-center-between dark:border-dark md:hidden">
                <div
                    className="icon-box md:hidden"
                    onClick={() => dispatch(closeFilterMenu())}
                >
                  <FiDelete/>
                </div>
                <p className="uppercase">Filters</p>
              </div>

              <div className="my-4"></div>
              <AdvancedSearch/>
              <div className="my-4"></div>
              {
                  categoryName && <EnquiryForm categoryName={categoryName} type="list"/>
              }
              {/* <Type /> */}
              {/* <PriceRange /> */}
              {/* <SocialIcons /> */}
              {/* <CTA /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertySix;
