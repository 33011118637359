import React, {useEffect, useRef} from "react";

import "./Modal.css";

export const Modal = ({ onSubmit, onCancel, closeModal, children, headerContent, footerContent }) => {
    const modalRef = useRef<HTMLDivElement>({});

    useEffect(() => {
        const handleClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener("click", handleClick, true);

        return () => {
            document.removeEventListener("click", handleClick, true);
        };
    }, [modalRef, closeModal]);

    return (
        <div
            className="modal-container "
            onClick={(e) => {
                if (e.target.className === "modal-container")
                    closeModal("Modal was closed");
            }}
        >
            <div className="modal">
                <div
                    className="modal-header"
                >
                    {headerContent==='OTP'?<span className="otpclose"><span onClick={() => closeModal("Modal was closed")}>&times;</span></span>
                    :<>{headerContent}<span className="close" onClick={() => closeModal("Modal was closed")}><span
                            onClick={() => closeModal("Modal was closed")}>&times;</span></span></>}
                </div>
                <div className="modal-content">{children}</div>
                <div className="modal-footer">
                    {footerContent}
                </div>
            </div>
        </div>
    );
};