import { BiBed, BiMap, BiMapAlt, BiTab } from "react-icons/bi";
import { Link } from "react-router-dom";
import CardHoverIcons from "./CardHoverIcons";
import CardLabels from "./CardLabels";
import {useEffect, useState} from "react";
import LocationService from "../../../services/location.service";
import ReactStars from "react-rating-stars-component";

const SingleProductCard = ({id,
                             name,
                             location,
                             price,
                             distance,
                             purpose,
                             number_of_beds,
                             number_of_bathrooms,
                             dimensions,
                             image,
                             orgDescription,
                             textLength,
                             showLabels,elasticContact,
                               averageRating,
                               elasticUserRatings,
    basis
}) => {
  const [add,setAdd] = useState('')
  const getLocationInfo = () => {
    LocationService.getLocationAddress(location.lat,location.lon)
        .then(function(response) {
          if(response!=null && response.data!==null && response.data.address!== null){
            setAdd(response.data);
          }else{
            setAdd("");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    return "";
  }
  useEffect(() => {
    getLocationInfo();
  }, [location]);
    const getWhatsAppHref = "//api.whatsapp.com/send?phone=91"+elasticContact?.mobileNo+"&text=Hi";
    const getDialPadHref= "tel:+91"+elasticContact?.mobileNo;
  return (
    <div
      className={`flex-1 ${
        basis ? basis : "basis-[18rem]"
      } shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group`}
    >
      <div className="group !opacity-100 overflow-hidden relative">
        <Link to={"/profiledetail/"+id} className="!opacity-100">
          <img src={"/images/property (25).jpg"}
            alt={name}
            className="w-full  h-fit md:h-[250px] object-cover group-hover:scale-125 transition-a"
          />
        </Link>
        <CardHoverIcons />
        <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 sm:translate-y-10 group-hover:translate-y-0 to-transparent">
          <div className="text-white flex-align-center gap-x-2">
            <BiMap />
            <p>{add?.display_name}</p>
          </div>
        </div>
      </div>
      {!showLabels && <CardLabels purpose={purpose} distance={distance} />}
      <div className="p-3">
        <Link to={"/profiledetail/"+id} className="group-hover:text-primary transition-a">
          <h1 className="text-lg font-bold capitalize">{name}</h1>
        </Link>
        <div className="flex justify-between mt-3">

          <div className="flex-align-center gap-x-2">
            <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
              <BiMapAlt/>
            </div>
            <p className="text-sm">{add?.display_name}</p>
          </div>
        </div>
        <p className="mt-2">{`${orgDescription.slice(
            0,
            textLength || 180
        )}`}</p>
          {
              elasticUserRatings?.length > 0 && <div className="flex justify-between mt-3">
                  <div className="flex-align-center gap-x-2">
                      <ReactStars
                          size={16}
                          isHalf={true}
                          activeColor="#ffd700"
                          value={averageRating}
                          edit={false}
                      />
                      <div className="text-gray-600 ml-2 text-sm md:text-base mt-1">{elasticUserRatings?.length} reviews</div>
                  </div>
              </div>
          }

          <div className="grid grid-cols-1 gap-10 mt-8 sm:grid-cols-3 md:grid-cols- lg:grid-cols-3"
               style={{gap: '10px'}}>
              <button className="btn btn-primary"><a target="_blank" href={getDialPadHref} rel="noreferrer">Call Now </a></button>
              <button className="btn btn-secondary">Enquiry Now</button>
              <button className="btn btn-light" style={{
                  display: 'flex',
                  border: '1px solid',
                  justifyContent: 'space-around',
                  textAlign: 'center',
                  alignItems: 'center'
              }}><a target="_blank" href={getWhatsAppHref} rel="noreferrer">
                  <div style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      textAlign: 'center',
                      alignItems: 'center',
                      gap: '6px'
                  }}>
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-7 w-7"
                          fill="currentColor"
                          style={{color: "#128c7e"}}
                          viewBox="0 0 24 24">
                          <path
                              d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
                      </svg>
                      Chat Now
                  </div>
              </a>
              </button>
          </div>
      </div>
    </div>
);
};

export default SingleProductCard;
