import Sidebar from "./Sidebar";
import './SellerSignup.css';
import PersonalInfo from "./PersonalInfo";
import React, {useContext, useEffect} from "react";
import StepperContext from "../../context/StepperContext";
import ThankYou from "../../pages/ThankYou";
import AddressInfo from "./AddressInfo";
import Plan from "./Plan";
import {useUserContext} from "../../context/user_context";
import SellerService from "../../services/seller.service";
import {GET_SELLERS_BEGIN, GET_SELLERS_ERROR, GET_SELLERS_SUCCESS} from "../../actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import LocationService from "../../services/location.service";
import {useLoading} from "../../context/LoadingContext";
import {Bounce, toast} from "react-toastify";

const SellerSignup = () => {
    const dispatch= useDispatch();
    const navigate = useNavigate();
    const {stepNumber,
        setStepNumber,
        goBackVisible, setGoBackVisible,
        steps, setSteps,
        yourInfo, setYourInfo,
        isEmpty, setIsEmpty,
        isPlanEmpty, setIsPlanEmpty,
        planDuration, setPlanDuration,
        planDurationName, setPlanDurationName,
        plan, setPlan,
        planOptions, setPlanOptions,
        addonOptions, setAddonOptions,
        addons, setAddons,
        country, setCountry,
        state, setState,
        city, setCity,
        displayThankyou, setDisplayThankyou,formFields,setFormFields,addressRequired,
        countryRequired,
        stateRequired,
        cityRequired,
        zipRequired} = useContext(StepperContext);
    const{location}=useUserContext();
    const {setLoading}=useLoading();

    useEffect(()=>{
        const mobileNumber=localStorage.getItem("mobileNumber");
        if(mobileNumber!==null){
            setYourInfo((prevInfo) => {
                return { ...prevInfo, ["phone"]: JSON.parse(mobileNumber) };
            });
            let newState = [...formFields];
            newState[2].isValid=true;
            setFormFields(newState);
        }
        setLoading(true)
        LocationService.getLocationInfo().then((response)=>{
            setLoading(false);
                setCountry({
                    value: response.address["ISO3166-2-lvl4"].split("-")[0],
                    label: response.address.country,
                    isSelected: true});
                setState({
                    value: response.address["ISO3166-2-lvl4"].split("-")[1] ==="TS"?"TG":response.address["ISO3166-2-lvl4"].split("-")[1],
                    label: response.address.state,
                    isSelected: true});
                setYourInfo((prevInfo) => {
                    return { ...prevInfo, ["address"]: response.display_name,["zip"]:response.address.postcode,["country"]:response.address["ISO3166-2-lvl4"].split("-")[0],["state"]:response.address["ISO3166-2-lvl4"].split("-")[1] ==="TS"?"TG":response.address["ISO3166-2-lvl4"].split("-")[1]};
                });
            }
        ).catch(err=>setLoading(false))
    },[])

    useEffect(() => {
        setSteps((prevSteps) => {
            return prevSteps.map((step) => {
                if (step.id === stepNumber) {
                    return {...step, active: true};
                } else {
                    return {...step, active: false};
                }
            });
        });
        if (stepNumber > 1) {
            setGoBackVisible("visible");
        } else {
            setGoBackVisible("invisible");
        }
    }, [
        stepNumber,
        yourInfo,
        plan,
        addons,
        addonOptions,
        planOptions,
        isPlanEmpty,
        displayThankyou,
    ]);
    const changeYourInfo = (event) => {
        setYourInfo((prevInfo) => {
            return { ...prevInfo, [event.target.name]: event.target.value };
        });
    };
    const toggleDuration = () => {
        if (plan.yearly === false) {
            setPlan((prevPlan) => {
                setPlanDuration("yr");
                setPlanDurationName("Yearly");

                setPlanOptions((prevPlanOptions) => {
                    return prevPlanOptions.map((planOption) => {
                        return {...planOption, price: planOption.yearlyPrice};
                    });
                });

                setAddonOptions((prevAddonOptions) => {
                    return prevAddonOptions.map((addonOption) => {
                        return {...addonOption, price: addonOption.yearlyPrice};
                    });
                });

                return { ...prevPlan, yearly: true };
            });
        } else {
            setPlan((prevPlan) => {
                setPlanDuration("basic");
                setPlanDurationName("Basic");

                setPlanOptions((prevPlanOptions) => {
                    return prevPlanOptions.map((planOption) => {
                        return {...planOption, price: planOption.monthlyPrice};
                    });
                });

                setAddonOptions((prevAddonOptions) => {
                    return prevAddonOptions.map((addonOption) => {
                        return {...addonOption, price: addonOption.monthlyPrice};
                    });
                });

                return { ...prevPlan, yearly: false };
            });
        }

        // setPlan((prevPlan) => {
        //   return { ...prevPlan, yearly: !plan.yearly };
        // });
    };
    const selectPlan = (title, price, id) => {
        setPlanOptions((prevPlanOptions) => {
            return prevPlanOptions.map((planOption) => {
                if (planOption.id === id) {
                    return {...planOption, selected: true};
                } else {
                    return {...planOption, selected: false};
                }
            });
        });

        setPlan((prevPlan) => {
            return { ...prevPlan, title: title, price: price };
        });
    };
    const nextStep = () => {
        if (stepNumber === 1) {
            const errorFields=formFields.filter(field => field.isValid === false);
            if(!errorFields.length>0){
                setStepNumber((prevStep) => prevStep + 1);
            }
        }

        if(stepNumber ===2){
          if(!(addressRequired==="block" ||
              countryRequired==="block" ||
              stateRequired==="block" ||
              cityRequired==="block" ||
              zipRequired==="block")){
              // setStepNumber((prevStep) => prevStep + 1);
              onSubmitSignUp();
          }
        }
        if (stepNumber === 3) {
            if (plan.title.length === 0) {
                setIsPlanEmpty(true);
                return;
            } else {
                setIsPlanEmpty(false);
            }
            setStepNumber((prevStep) => prevStep + 1);
        }


    };

    const prevStep = () => {
        setStepNumber((prevStep) => prevStep - 1);
    };

    const changeClick = () => {
        setStepNumber((prevStep) => prevStep - 2);
    };

    function onSubmitSignUp() {
        dispatch({type: GET_SELLERS_BEGIN})
        const payload={};
        payload.name=yourInfo.name;
        payload.orgDescription=yourInfo.description;
        payload.contact={mobileNo:yourInfo.phone,emailId:yourInfo.email}
        payload.sellerCategories=[];
        yourInfo.category?.map(data=> payload.sellerCategories.push({categoryName:data.label,categoryId:data.value}))
        payload.address={};
        payload.address={addressLine1:yourInfo.address,zip:yourInfo.zip,state:yourInfo.state,country:yourInfo.country,city:yourInfo.city?.value};
        payload.location=location;
        SellerService.createSeller(payload).then((response)=>{
            if(response.data!==null){
                toast.success('Signup Successfully ', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    theme: "light",
                    transition: Bounce,
                });
                dispatch({type: GET_SELLERS_SUCCESS});
                navigate("/profiledetail/"+response.data?.id);
            }
        }).catch((err)=> dispatch({type: GET_SELLERS_ERROR}))
    }

    return(
        <React.Fragment>
                <div className="sellerSignup mt-16">
                    <div
                        className="sm:w-[60rem] mt-[100px] sm:mt-0 rounded-xl shadow-xl p-4 flex flex-col sm:flex sm:flex-row justify-between">
                        <Sidebar/>
                        <div className="sm:basis-[60%] w-[300px] sm:w-[100%] h-[100%] sm:pr-[80px]">
                            {(displayThankyou && (
                                //<div className="flex flex-col justify-between absolute top-40 w-[450px] md:static mb-40 rounded-2xl mx-8 px-16 pt-10 pb-16 bg-white md:px-0 md:py-5 md:mx-28 md:w-100 md:my-2">
                                <>
                                    <ThankYou/>
                                </>
                                //</div>
                            )) || (
                                // <div className="flex flex-col justify-between absolute top-40 w-[450px] md:static mb-40 rounded-2xl mx-8 px-16 pt-10 pb-16 bg-white md:px-0 md:py-5 md:mx-28 md:w-100 md:my-2">
                                <>
                                    <div>
                                        {(stepNumber === 1 && (
                                            <>
                                            <PersonalInfo
                                                onChangeYourInfo={changeYourInfo}
                                                yourInfo={yourInfo}
                                                currentStep={stepNumber}
                                            />
                                            </>
                                            )) ||
                                            (stepNumber === 2 && (
                                                <AddressInfo />
                                            ))
                                            // ||
                                            // (stepNumber === 3 && (
                                            //     <Plan
                                            //         onPlanSelect={selectPlan}
                                            //         onToggleDuration={toggleDuration}
                                            //         currentStep={stepNumber}
                                            //         planOptions={planOptions}
                                            //         isPlanEmpty={isPlanEmpty}
                                            //         planDuration={planDuration}
                                            //     />
                                            // ))
                                        // ))
                                            // )) ||
                                            // (stepNumber === 2 && (
                                            //     <Plan
                                            //         onPlanSelect={selectPlan}
                                            //         onToggleDuration={toggleDuration}
                                            //         currentStep={stepNumber}
                                            //         planOptions={planOptions}
                                            //         isPlanEmpty={isPlanEmpty}
                                            //         planDuration={planDuration}
                                            //     />
                                            // )) ||
                                            // (stepNumber === 3 && (
                                            //     <Addons
                                            //         onBoxCheck={checkBox}
                                            //         currentStep={stepNumber}
                                            //         addonOptions={addonOptions}
                                            //         planDuration={planDuration}
                                            //     />
                                            // )) ||
                                            // (stepNumber === 4 && (
                                            //     <Summary
                                            //         selectedPlan={plan}
                                            //         selectedAddons={addons}
                                            //         currentStep={stepNumber}
                                            //         planDuration={planDuration}
                                            //         planDurationName={planDurationName}
                                            //         onChangeClick={changeClick}
                                            //     />
                                            // ))
                                        }
                                    </div>
                                    <div
                                        className=" py-[2px] mt-[120px] sm:mt-[26px]">
                                        {
                                            stepNumber === 1 && (
                                                <div className="flex justify-end">
                                                <button
                                                    onClick={nextStep}
                                                    className="btn-primary text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
                                                    type="sumbit"
                                                >
                                                    Next Step
                                                </button>
                                                </div>
                                            )
                                        }
                                        {
                                            (stepNumber === 2 )  && (
                                                <div className="flex justify-between">
                                                    <button
                                                        onClick={prevStep}
                                                        style={{    display: 'flex',
                                                            border: '1px solid'
                                                        }}
                                                        className="btn-light border-1 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
                                                        type="button"
                                                    >
                                                        Go Back
                                                    </button>
                                                    <button
                                                        onClick={nextStep}
                                                        className="btn-primary text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
                                                        type="sumbit"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>

                                            )
                                        }
                                        {/*Enable Plan*/}
                                        {/*{*/}
                                        {/*    (stepNumber === 3 )  && (*/}
                                        {/*        <div className="flex justify-between">*/}
                                        {/*            <button*/}
                                        {/*                onClick={prevStep}*/}
                                        {/*                style={{    display: 'flex',*/}
                                        {/*                    border: '1px solid'*/}
                                        {/*                }}*/}
                                        {/*                className="btn-light border-1 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"*/}
                                        {/*                type="button"*/}
                                        {/*            >*/}
                                        {/*                Go Back*/}
                                        {/*            </button>*/}
                                        {/*            <button*/}
                                        {/*                onClick={onSubmitSignUp}*/}
                                        {/*                className="btn-primary text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"*/}
                                        {/*                type="sumbit"*/}
                                        {/*            >*/}
                                        {/*                Submit*/}
                                        {/*            </button>*/}
                                        {/*        </div>*/}

                                        {/*    )*/}
                                        {/*}*/}
                                        {/* {stepNumber != 1 && (
              <div
                onClick={prevStep}
                className={`font-medium text-[#9699ab] cursor-pointer transition duration-100 hover:text-[#02295a] ${goBackVisible}`}
              >
                Go back
              </div>
            )} */}
                                        {/*<div*/}
                                        {/*    onClick={prevStep}*/}
                                        {/*    className={`font-medium text-[#9699ab] select-none cursor-pointer transition duration-100 hover:text-[#02295a] ${goBackVisible}`}*/}
                                        {/*>*/}
                                        {/*    Go back*/}
                                        {/*</div>*/}
                                        {/*{stepNumber === 4 ? (*/}
                                        {/*    <div*/}
                                        {/*        onClick={() => setDisplayThankyou(true)}*/}
                                        {/*        className="font-medium bg-[#473dff] select-none text-white py-3 px-5 rounded-lg cursor-pointer transition duration-100 hover:opacity-90"*/}
                                        {/*    >*/}
                                        {/*        Confirm*/}
                                        {/*    </div>*/}
                                        {/*) : (*/}
                                        {/*    // <div*/}
                                        {/*    //     onClick={nextStep}*/}
                                        {/*    //     className="font-medium bg-[#02295a] select-none text-white py-3 px-5 rounded-lg cursor-pointer transition duration-100 hover:opacity-90"*/}
                                        {/*    // >*/}
                                        {/*    //     Next Step*/}
                                        {/*    // </div>*/}

                                        {/*)}*/}
                                    </div>
                                </>
                                // </div>
                            )}
                        </div>
                    </div>
                </div>
        </React.Fragment>

    )
}
export default SellerSignup;