import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, HashRouter} from "react-router-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./app/store";
import { AnimatePresence } from "framer-motion";
import {UserProvider} from "./context/user_context";
import {SellersProvider} from "./context/sellers_context";
import {FilterProvider} from "./context/filter_context";
import {AddOnsProvider} from "./context/AddOnsContext";
import {PlansProvider} from "./context/PlansContext";
import {StepperProvider} from "./context/StepperContext";
import {LoadingProvider} from "./context/LoadingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

      <Provider store={store}>
          <LoadingProvider>
          <UserProvider>
              <SellersProvider>
                  <FilterProvider>
                      <StepperProvider>
                      <PlansProvider>
                      <AddOnsProvider>
                      <HashRouter>
                      <AnimatePresence>
                          <App />
                      </AnimatePresence>
                      </HashRouter>
                      </AddOnsProvider>
                      </PlansProvider>
                      </StepperProvider>
                  </FilterProvider>
              </SellersProvider>
          </UserProvider>
          </LoadingProvider>
      </Provider>
  // </React.StrictMode>
);
