import axios from "axios";

export default class OrderService {

    static API_URL = String(process.env.REACT_APP_SERVICE_URL);
    static API_PREFIX_URL = String(process.env.REACT_APP_ORDER_SERV_PREFIX);
    static URL= OrderService.API_URL.concat(OrderService.API_PREFIX_URL);

    static createOrder =  (request) => {
        return axios.post(OrderService.URL + '/createOrder',request,{
            headers: {
                // 'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }

    static updateOrder =  (request) => {
        return axios.post(OrderService.URL + '/updateOrder',request,{
            headers: {
                // 'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
}