import axios from "axios";

export default class LocationService{

    static Location={};

   static getLocationInfo =  () => {
       return new Promise((resolve, reject) => {
           if (navigator.geolocation) {
               navigator.permissions
                   .query({name: "geolocation"})
                   .then(function (result) {
                       console.log(result);
                       if (result.state === "granted") {
                           //If granted then you can directly call your function here
                           navigator.geolocation.getCurrentPosition((pos) => {
                               LocationService.Location=pos.coords;
                               LocationService.getLocationAddress(pos.coords.latitude, pos.coords.longitude).then(function(response) {
                                   if(response!=null && response.data!==null){
                                       resolve(response.data);
                                   }
                               })
                               // return pos.coords;
                           }, (errors) => console.warn(errors), {
                               enableHighAccuracy: true,
                               timeout: 5000,
                               maximumAge: 600,
                           });
                       } else if (result.state === "prompt") {
                           //If prompt then the user will be asked to give permission
                           navigator.geolocation.getCurrentPosition((pos) => {
                               LocationService.getLocationAddress(pos.coords.latitude, pos.coords.longitude).then(function(response) {
                                   if(response!=null && response.data!==null && response.data.address!== null){
                                       resolve(response.data.display_name);
                                   }
                               })
                           }, (errors) => console.warn(errors), {
                               enableHighAccuracy: true,
                               timeout: 5000,
                               maximumAge: 600,
                           });
                       } else if (result.state === "denied") {
                           //If denied then you have to show instructions to enable location
                           reject("Location Denied")
                       }
                   });
           } else {
               console.log("Geolocation is not supported by this browser.");
               reject("Geolocation is not supported by this browser.");
           }
       });
    }
    static getLocationAddress = (latitude,longitude) => {
        const urlLocation = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        return axios.get(urlLocation,{
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
}