import { SET_LOCATION_DATA} from "../actions";

const userReducer = (state, action) => {
    switch (action.type) {
        case SET_LOCATION_DATA:
            return {
                ...state,
                location: action.payload
            };
        default:
            return state;
    }
}

export default userReducer;