import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import BackToTopButton from "./components/common/BackToTopButton";
import Footer from "./components/common/Footer";
import Navbar from "./components/common/Navbar";
import {
  HomeThree,
  About,
  PropertySix,
  Contact,
  PageNotFound,
  Pricing,
  ProfileDetail
} from "./pages";
import { closeDropdown } from "./features/uiSlice";
import Dropdown from "./components/common/DropDown";
import Loader from "./components/common/Loader";
import {useFilterContext} from "./context/filter_context";
import PersonalInfo from "./components/signup/PersonalInfo";
import SelectPlan from "./pages/SelectPlan";
import AddOns from "./pages/AddOns";
import Summary from "./pages/Summary";
import ThankYou from "./pages/ThankYou";
import SellerSignup from "./components/signup/SellerSignup";
import {useUserContext} from "./context/user_context";
import {useLoading} from "./context/LoadingContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [showButton, setShowButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const route = useLocation();
  const {updateFilters} = useFilterContext();
  // const {sellers_loading} = useSellersContext();
  const {loading} = useLoading();
  const{setLocationData} = useUserContext();

  // Show/Hide scroll to top button
  window.addEventListener("scroll", () => {
    window.scrollY > 500 ? setShowButton(true) : setShowButton(false);
  });

  const handleCloseDropdown = (e) => {
    dispatch(closeDropdown());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600,
  };
  function success(pos) {
    var crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    setLocationData({lat:crd.latitude,lon:crd.longitude})
    updateFilters({lat:crd.latitude,lon:crd.longitude},"location")
    // getLocationInfo(crd.latitude, crd.longitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            console.log(result);
            if (result.state === "granted") {
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "prompt") {
              //If prompt then the user will be asked to give permission
              navigator.geolocation.getCurrentPosition(success, errors, options);
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
          });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  // Loader when page is loading
  window.addEventListener("load", () => {
    setShowLoader(false);
  });

  return (
    <div>
      {/*<UserLocation />*/}
      {loading && <Loader />}
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />
      {/* Same as */}
      {/*<ToastContainer />*/}
      <Navbar />
      <Dropdown />
      <div
        className="min-h-screen pb-40"
        onClick={handleCloseDropdown}
        onMouseOver={() => dispatch(closeDropdown())}
      >
        <Routes>
          <Route path="/" element={<HomeThree />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/viewById/:id" element={<PropertySix />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact path='/pricing' element={<Pricing />} />
          <Route exact path='/sellersignup' element={<SellerSignup />} />
          <Route exact path='/profiledetail/:id' element={<ProfileDetail />} />
          <Route path="/personal" element={<PersonalInfo />} />
          <Route path="/selectplan" element={<SelectPlan />} />
          <Route path="/addons" element={<AddOns />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <div className="px-[2%] md:px-[6%] bg-card-dark border border-card-dark">
        {/* <NewsLetter /> */}
        <div className="mt-5">
          <Footer />
        </div>
      </div>
      <BackToTopButton showButton={showButton} />
    </div>
  );
}

export default App;
