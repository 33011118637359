import { createContext, useState } from "react";

const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
    const [stepNumber, setStepNumber] = useState(() => 1);
    const [goBackVisible, setGoBackVisible] = useState("invisible");
    const [steps, setSteps] = useState([
        { id: 1, title: "YOUR INFO", active: true },
        { id: 2, title: "ADDRESS INFO", active: true },
        // { id: 3, title: "SELECT PLAN", active: false }
    ]);

    const [yourInfo, setYourInfo] = useState({
        name: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        city: "",
        address:"",
        zip:"",
        description:""
    });
    const [formFields, setFormFields] = useState([
        {
            id: 1,
            name: "name",
            label: "Name",
            type:"text",
            placeholder: "e.g John Doe",
            isValid:false,
            disabled:false,
            options:[],
            isMultiple:false
        },
        {
            id: 2,
            name: "email",
            type:"email",
            label: "Email Address",
            placeholder: "e.g john@gmail.com",
            isValid:false,
            options:[],
            isMultiple:false,
            disabled:false,
        },
        {
            id: 3,
            name: "phone",
            type:"tel",
            label: "Phone Number",
            placeholder: "e.g +1 234 567 890",
            isValid:false,
            options:[],
            isMultiple:false,
            disabled:true,
        },
        {
            id: 4,
            name: "category",
            type:"select",
            label: "Category",
            placeholder: "Select Category",
            isValid:false,
            options:[],
            isMultiple:true,
            disabled:false,
        },
        {
            id: 5,
            name: "description",
            type:"textarea",
            label: "Description",
            placeholder: "Enter description",
            isValid:false,
            disabled:false,
        },
    ]);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isPlanEmpty, setIsPlanEmpty] = useState(false);
    const [planDuration, setPlanDuration] = useState("basic");
    const [planDurationName, setPlanDurationName] = useState("Basic");
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const [country, setCountry] = useState(countryData[0]);
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [plan, setPlan] = useState({
        title: "",
        price: 0,
        yearly: false,
    });

    const [planOptions, setPlanOptions] = useState([
        {
            id: 1,
            logo: 'images/signup/icon-arcade.svg',
            title: "basic",
            price: 0,
            monthlyPrice: 0,
            yearlyPrice: 0,
            selected: false,
        },
        {
            id: 2,
            logo: 'images/signup/icon-advanced.svg',
            title: "Advanced",
            price: 12,
            monthlyPrice: 12,
            yearlyPrice: 120,
            selected: false,
        },
        {
            id: 3,
            logo: 'images/signup/icon-pro.svg',
            title: "Pro",
            price: 15,
            monthlyPrice: 15,
            yearlyPrice: 150,
            selected: false,
        },
    ]);

    const [addonOptions, setAddonOptions] = useState([
        {
            id: 1,
            title: "Online service",
            desc: "Access to multiplayer games",
            price: 1,
            monthlyPrice: 1,
            yearlyPrice: 10,
            selected: false,
        },
        {
            id: 2,
            title: "Larger storage",
            desc: "Extra 1TB of cloud save",
            price: 2,
            monthlyPrice: 2,
            yearlyPrice: 20,
            selected: false,
        },
        {
            id: 3,
            title: "Customizable profile",
            desc: "Custom theme on your profile",
            price: 2,
            monthlyPrice: 2,
            yearlyPrice: 20,
            selected: false,
        },
    ]);

    const [addons, setAddons] = useState([]);
    const [addressRequired, setAddressRequired] = useState("hidden");
    const [countryRequired, setCountryRequired] = useState("hidden");
    const [stateRequired, setStateRequired] = useState("hidden");
    const [cityRequired, setCityRequired] = useState("hidden");
    const [zipRequired, setZipRequired] = useState("hidden");
    const [displayThankyou, setDisplayThankyou] = useState(false);
    return (
        <StepperContext.Provider
            value={{
                stepNumber,
                setStepNumber,
                goBackVisible, setGoBackVisible,
                steps, setSteps,
                yourInfo, setYourInfo,
                isEmpty, setIsEmpty,
                isPlanEmpty, setIsPlanEmpty,
                planDuration, setPlanDuration,
                planDurationName, setPlanDurationName,
                plan, setPlan,
                planOptions, setPlanOptions,
                addonOptions, setAddonOptions,
                addons, setAddons,
                displayThankyou, setDisplayThankyou,
                countryData, setCountryData,
                stateData, setStateData,
                cityData, setCityData,
                country, setCountry,
                state, setState,
                city, setCity,
                formFields, setFormFields,
                addressRequired, setAddressRequired,
                countryRequired, setCountryRequired,
                stateRequired, setStateRequired,
                cityRequired, setCityRequired,
                zipRequired, setZipRequired
            }}
        >
            {children}
        </StepperContext.Provider>
    );
};

export default StepperContext;
