import {useSellersContext} from "../../../../context/sellers_context";
import { City, State } from "country-state-city";
import React, {useEffect} from "react";
import {useFilterContext} from "../../../../context/filter_context";

const AdvancedSearch = () => {
    const {categoryData,fetchSellers} = useSellersContext();
    const {
        filters,
        updateFilters,
        countryData,
        stateData,
        cityData,
        setCityData,
        setStateData,
        clearFilters,
    } = useFilterContext();
    const {country,state,city,zip,distance,category} = filters;
    useEffect(() => {
        setStateData(State.getStatesOfCountry(country));
    }, [country]);

    useEffect(() => {
        setCityData(City.getCitiesOfState(country, state));
    }, [state]);

    useEffect(() => {
        stateData && updateFilters(stateData[0]?.isoCode,"state");
    }, [stateData]);

    useEffect(() => {
        cityData && updateFilters(cityData[0]?.isoCode,"city");
    }, [cityData]);

    const handleChange = (e,selectOptionSetter) => {
        updateFilters(e,selectOptionSetter);
    }
    const onSearch = (e) => {
        e.preventDefault();
        fetchSellers({filters:filters});
    }
  return (
      <div className="p-3 border dark:border-dark">
          <h1 className="font-semibold">Advanced Search</h1>
          <div className="mt-3">
              <select name="category" id="" className="filter"
                      value={category}
                      onChange={e => handleChange(e.target.value, "category")}>
                  <option value="">Categories</option>
                  {
                      categoryData?.length > 0 && categoryData?.map((element, index) => {
                          return (
                              <option key={index} value={element.id}>{element.name}</option>
                          );
                      })
                  }
              </select>
          </div>
          <div className="mt-3">
              <select name="country" id="" className="filter" value={country}
                      onChange={e => handleChange(e.target.value, "country")}>
                  <option value="">Country</option>
                  {
                      countryData.length > 0 && countryData?.map((element, index) => {
                          return (
                              <option key={index} value={element.isoCode}>{element.name}</option>
                          );
                      })
                  }
              </select>
          </div>
          <div className="mt-3">
              <select name="state" id="" className="filter"
                      value={state}
                      onChange={e => handleChange(e.target.value, "state")}>
                  <option value="">State</option>
                  {
                      stateData && stateData?.map((element, index) => {
                          return (
                              <option key={index} value={element.isoCode}>{element.name}</option>
                          );
                      })
                  }
              </select>
          </div>
          <div className="mt-3">
              <select name="city" id="" className="filter"
                      value={city}
                      onChange={e => handleChange(e.target.value, "city")}>
                  <option value="">City</option>
                  {
                      cityData && cityData?.map((element, index) => {
                          return (
                              <option key={index} value={element.isoCode}>{element.name}</option>
                          );
                      })
                  }
              </select>
          </div>
          <div className="mt-3">
              <select name="distance" id="" className="filter" value={distance} onChange={(e)=> updateFilters(e.target.value,"distance")}>
                  <option value="">Distance</option>
                  <option value="10 m">10 Meters</option>
                  <option value="100 m">100 Meters</option>
                  <option value="1 km">1 Kilometer</option>
                  <option value="5 km">5 Kilometer</option>
                  <option value="10 km">10 Kilometer</option>
                  <option value="15 km">15 Kilometer</option>
                  <option value="25 km">25 Kilometer</option>
                  <option value="50 km">50 Kilometer</option>
                  <option value="100 km">100 Kilometer</option>
              </select>
          </div>
          <div className="mt-3">
              <input
                  type="text"
                  name="zip"
                  value={zip}
                  onChange={(e)=> updateFilters(e.target.value,"zip")}
                  className="border outline-none bg-transparent dark:border-dark px-3 py-[0.35rem] w-full"
                  placeholder="Enter Zip code"
              />
          </div>
          <button className="btn bg-secondary w-full mt-4 text-slate-200 !rounded-none" onClick={onSearch}>
              search
          </button>
          <button className="btn btn-light !rounded-none w-full mt-4" style={{    display: 'flex',
              border: '1px solid',
              justifyContent: 'space-around',
              textAlign: 'center',
              alignItems: 'center'
          }} onClick={()=>clearFilters}> Clear Filters
          </button>
      </div>
  );
};

export default AdvancedSearch;
