import { useEffect, useState } from "react";
import {useUserContext} from "../../../context/user_context";
import {useFilterContext} from "../../../context/filter_context";
import LocationService from "../../../services/location.service";

const APIkey = "1c48c4f54936472a9613e082baa04124";

const UserLocation = () =>{
    // const {setLocationData} = useUserContext();
    const {updateFilters} = useFilterContext();
    // const [location, setLocation] = useState();
    // const [add,setAdd] = useState('')
    //
    // function getLocationInfo(latitude, longitude) {
    //     // const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`;
    //     // fetch(url)
    //     //     .then((response) => response.json())
    //     //     .then((data) => {
    //     //         console.log(data);
    //     //         if (data.status.code === 200) {
    //     //             console.log("results:", data.results);
    //     //             setLocation(data.results[0].formatted);
    //     //         } else {
    //     //             console.log("Reverse geolocation request failed.");
    //     //         }
    //     //     })
    //     //     .catch((error) => console.error(error));
    //     const urlLocation = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    //     fetch(urlLocation).then(res=>res.json()).then(data=>setAdd(data.address))
    // }
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 600,
    };
    function success(pos) {
        var crd = pos.coords;
        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);
        // setLocationData({lat:crd.latitude,lon:crd.longitude})
        updateFilters({lat:crd.latitude,lon:crd.longitude},"location")
        LocationService.Location={lat:crd.latitude,lon:crd.longitude};
       // getLocationInfo(crd.latitude, crd.longitude);
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    console.log(result);
                    if (result.state === "granted") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "prompt") {
                        //If prompt then the user will be asked to give permission
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);

    // return (
    //     <div>
    //         {location ? <>Your location: {location}</> : null}
    //         <>
    //         {
    //             add?<>
    //             <p>village : {add.village}</p>
    //             <p>state_district : {add.state_district}</p>
    //             <p>country :{add.country}</p></>:""
    //         }
    //         </>
    //     </div>
    // );
}
export default UserLocation;