import { useSelector } from "react-redux";
import { dataStore } from "../../../features/dataSlice";
import SingleProductCard from "../../common/page-componets/SingleProductCard";
import {useSellersContext} from "../../../context/sellers_context";
import {useFilterContext} from "../../../context/filter_context";
import {useEffect} from "react";

const PropertyList = ({ basis }) => {
  // const {sellers} = useSellersContext();
    const { filtered_sellers } = useFilterContext();
  return (
      <>
      {
          filtered_sellers.length > 0 ? <div className="flex flex-wrap gap-4">
          {filtered_sellers?.map((property) => (
              <SingleProductCard key={property.id} {...property} basis={basis}/>
          ))}
        </div> : <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>No Sellers Found</div>
      }
      </>
  );
};

export default PropertyList;
