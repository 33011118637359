import React, {useContext, useEffect, useReducer, useState} from 'react'
import reducer from "../reducers/user_reducer";
import {SET_LAT_DATA, SET_LOCATION_DATA, SET_STATE_DATA} from "../actions";

const UserContext = React.createContext()

const initialState = {
  location:{},
  userData:{}
}
export const UserProvider = ({ children }) => {
  // const { loginWithRedirect, logout, user, isLoading, error } = useAuth0()
  const [state, dispatch] = useReducer(reducer, initialState)
  // const [myUser, setMyUser] = useState(null)

  // useEffect(() => {
  //   setMyUser(user)
  // }, [user])

  const setLocationData = (data)=>{
    dispatch({type: SET_LOCATION_DATA,payload:data});
  }

  return (
    <UserContext.Provider
      // value={{ loginWithRedirect, logout, myUser, isLoading, error }}
     value={{
           ...state,setLocationData}}>
      {children}
    </UserContext.Provider>
  )
}
// make sure use
export const useUserContext = () => {
  return useContext(UserContext)
}
