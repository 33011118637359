import './Category.css';
import { useNavigate } from 'react-router-dom';
import {useFilterContext} from "../../../context/filter_context";

const Category = (props) => {
    const navigate = useNavigate();
    const {updateFilters} = useFilterContext();
    const handleClick = (e,element) => {
        e.preventDefault();
        updateFilters(element.id,"category");
        navigate("/viewById/"+element.id);
        // navigate("/viewById/",{state:{id:element.id,name:element.name}});
    };
return(
    <>
<div>
 <div className="grid grid-cols-2 gap-3 mt-8 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8">
     {
         props.categoryData?.length>0 && props.categoryData?.map((element, index) => {
         return (
                 <div className='featured' key={"category"+index} onClick={(e)=>handleClick(e,element)}>
                     <div className="content">
                         <div className="box">
                             <img src={`images/icons/${element.value}.png`} alt=""/>
                             <h4>{element.name}</h4>
                         </div>
                     </div>
                 </div>
            );
         })
     }
 </div>
</div>
</>
);
}
export default Category;