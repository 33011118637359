import axios from 'axios'
import React, { useContext, useEffect, useReducer } from 'react'
import reducer from '../reducers/sellers_reducer'
import {
  GET_SELLERS_BEGIN,
  GET_SELLERS_SUCCESS,
  GET_SELLERS_ERROR,
  GET_SINGLE_SELLER_BEGIN,
  GET_SINGLE_SELLER_SUCCESS,
  GET_SINGLE_SELLER_ERROR, GET_CATEGORY_BEGIN, GET_CATEGORY_ERROR, GET_CATEGORY_SUCCESS,
} from '../actions'
import ElasticService from "../services/elastic.service";
import CategoryService from "../services/category.service";
import {useLoading} from "./LoadingContext";
import {useUserContext} from "./user_context";

const initialState = {
  isSidebarOpen: false,
  sellers_loading: false,
  sellers_error: false,
  sellers: [],
  categoryData: [],
  featured_sellers: [],
  single_seller_loading: false,
  single_seller_error: false,
  single_seller: {},
}

const SellersContext = React.createContext()

export const SellersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {setLoading}=useLoading();
  const{locationData} = useUserContext();

  const isEmpty = (str)=> {
    return (!str || str.length === 0);
  }
  const fetchSellers =    (filterData) => {
    setLoading(true);
    // const response = await axios.get(url)
    // const sellers = response.data
    processRequest(filterData);
  }

  const processRequest = (filterData) => {
    const criteriaPayload = {};
    criteriaPayload.criteria = {};
    criteriaPayload.criteria.queryName = "fetchSellers";
    criteriaPayload.criteria.paramaList = [];
    criteriaPayload.criteria.location = {};
    if (filterData.filters) {
      const {text, country, state, city, zip, distance, category} = filterData.filters;
      if (!isEmpty(category))
        criteriaPayload.criteria.paramaList.push({
          type: "STRING",
          operation: "MUST",
          name: "sellerCategories.categoryId",
          value: category
        })
      if (!isEmpty(text))
        criteriaPayload.criteria.paramaList.push({type: "STRING", operation: "LIKE", name: "name", value: text})
      if (!isEmpty(country))
        criteriaPayload.criteria.paramaList.push({
          type: "STRING",
          operation: "MUST",
          name: "addresses.country",
          value: country
        })
      if (!isEmpty(state))
        criteriaPayload.criteria.paramaList.push({
          type: "STRING",
          operation: "MUST",
          name: "addresses.state",
          value: state
        })
      if (!isEmpty(city))
        criteriaPayload.criteria.paramaList.push({
          type: "STRING",
          operation: "MUST",
          name: "addresses.city",
          value: city
        })
      if (!isNaN(parseInt(zip)))
        criteriaPayload.criteria.paramaList.push({
          type: "NUMBER",
          operation: "MUST",
          name: "addresses.zip",
          value: zip
        })
      if(locationData && locationData.location && !(isEmpty(locationData.location.lat) && isEmpty(locationData.location.lon))){
        fetchSellersWithLatLon(criteriaPayload,distance,locationData.location.lat,locationData.location.lon);
      }else {
        navigator.geolocation.getCurrentPosition((pos)=>{
          const {latitude,longitude}=pos.coords;
          fetchSellersWithLatLon(criteriaPayload,distance,latitude,longitude);
        }, (errors)=>{
          if(errors.PERMISSION_DENIED ===1){
            console.log("Please enable location")
            alert("Please accept location info");
            fetchSellersWithOut(criteriaPayload);
          }
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 600,
        });
      }

    }
  }

  const fetchSellersWithOut = (criteriaPayload) => {
    ElasticService.fetchResults(criteriaPayload).then(function (response) {
      if (response != null && response.data !== null && response.data.response !== null) {
        setLoading(false);
        dispatch({type: GET_SELLERS_SUCCESS, payload: JSON.parse(response.data.response)})
      } else{
        setLoading(false);
        dispatch({type: GET_SELLERS_SUCCESS, payload: []})
      }

    })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          dispatch({type: GET_SELLERS_ERROR});
        });
  }
  const fetchSellersWithLatLon = (criteriaPayload,distance,latitude,longitude) => {
    if (!isEmpty(distance)) {
      criteriaPayload.criteria.location.lat = latitude;
      criteriaPayload.criteria.location.lon = longitude;

      if(distance.split(" ")[1] === "m"){
        criteriaPayload.criteria.unit = "m";
      }else if(distance.split(" ")[1] === "km"){
        criteriaPayload.criteria.unit = "km";
      }
      criteriaPayload.criteria.distance = distance.split(" ")[0];
    } else if (!(isEmpty(latitude) && isEmpty(longitude))) {
      criteriaPayload.criteria.location.lat = latitude;
      criteriaPayload.criteria.location.lon = longitude;
      criteriaPayload.criteria.unit = "km";
      criteriaPayload.criteria.distance = 1000;
    }
    ElasticService.fetchResults(criteriaPayload).then(function (response) {
      if (response != null && response.data !== null && response.data.response !== null) {
        setLoading(false);
        dispatch({type: GET_SELLERS_SUCCESS, payload: JSON.parse(response.data.response)})
      } else{
        setLoading(false);
        dispatch({type: GET_SELLERS_SUCCESS, payload: []})
      }

    })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          dispatch({type: GET_SELLERS_ERROR})
        });
  }
  const fetchSingleProduct = async (url) => {
    setLoading(true);
    try {
      const response = await axios.get(url)
      const singleProduct = response.data
      setLoading(false);
      dispatch({ type: GET_SINGLE_SELLER_SUCCESS, payload: singleProduct })
    } catch (error) {
      setLoading(false);
    }
  }

  const getAllCategories = async () => {
    setLoading(true);
      await CategoryService.getAllCategories().then(function(response) {
        if(response!=null && response.data!==null){
          setLoading(false);
          dispatch({ type: GET_CATEGORY_SUCCESS, payload: response.data })
        }else{
          setLoading(false);
          dispatch({ type: GET_CATEGORY_SUCCESS, payload: [] });
        }

      })
        .catch(function(error) {
          console.log(error);
          setLoading(false);
          dispatch({ type: GET_CATEGORY_ERROR });
        });
  }

  useEffect(() => {
    getAllCategories();
    // fetchSellers();
  }, [])

  return (
    <SellersContext.Provider
      value={{
        ...state,
        fetchSingleProduct,
        fetchSellers
      }}
    >
      {children}
    </SellersContext.Provider>
  )
}
// make sure use
export const useSellersContext = () => {
  return useContext(SellersContext)
}
