import axios from "axios";

export default class OtpService {

    static API_URL = String(process.env.REACT_APP_SERVICE_URL);
    static API_PREFIX_URL = String(process.env.REACT_APP_OTP_SERV_PREFIX);
    static URL= OtpService.API_URL.concat(OtpService.API_PREFIX_URL);

    static sendOtp = (request) => {
        return axios.post(OtpService.URL + '/sendOtp',request,{
            headers: {
                // 'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }

    static authorizeOtp = (request) => {
        return axios.post(OtpService.URL + '/authorizeOtp',request,{
            headers: {
                // 'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response=>response)
            .catch(err=>{
                return err;
            })
    }
}