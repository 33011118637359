import {
  LOAD_SELLERS,
  SET_LISTVIEW,
  SET_GRIDVIEW,
  UPDATE_SORT,
  SORT_SELLERS,
  UPDATE_FILTERS,
  FILTER_SELLERS,
  CLEAR_FILTERS, SET_COUNTRY_DATA, SET_CITY_DATA, SET_STATE_DATA,
} from '../actions'
const filterReducer = (state, action) => {
  switch (action.type) {
    case SET_COUNTRY_DATA:
      return {
        ...state,
        countryData: action.payload,
      };

    case SET_CITY_DATA:
      return {
        ...state,
        cityData: action.payload,
      };
    case SET_STATE_DATA:
      return {
        ...state,
        stateData: action.payload,
      };
    case LOAD_SELLERS:
      return {
        ...state,
        all_sellers: [...action.payload],
        filtered_sellers: [...action.payload],
        filters: { ...state.filters},
      };

    case SET_GRIDVIEW:
      return {
        ...state,
        grid_view: "grid",
      };

    case SET_LISTVIEW:
      return {
        ...state,
        grid_view: "list",
      };

    case "GET_SORT_VALUE":
      // let userSortValue = document.getElementById("sort");
      // let sort_value = userSortValue.options[userSortValue.selectedIndex].value;
      // console.log(sort_value);
      return {
        ...state,
        sort: action.payload,
      };

    case CLEAR_FILTERS:
      // let userSortValue = document.getElementById("sort");
      // let sort_value = userSortValue.options[userSortValue.selectedIndex].value;
      // console.log(sort_value);
      return {
        ...state,
        filters: {
          ...state.filters,
          category: '',
          text: '',
          country: '',
          state: '',
          city: '',
          zip: '',
          distance: ''
        }
      };

    case SORT_SELLERS:
      let newSortData;
      let tempSortProduct = [...action.payload];

    function sortingProducts(a, b) {
      if (state.sort === "rating") {
        return (+a?.averageRating > +b?.averageRating ? -1 : 1);
      }

      if (state.sort === "distance") {
        console.log("call");
        return b.distance - a.distance;
      }

      if (state.sort === "relavence") {
        return a.name.localeCompare(b.name);
      }

      if (state.sort === "z-a") {
        return b.name.localeCompare(a.name);
      }
    }

      newSortData = tempSortProduct.sort(sortingProducts);
      // console.log("newsort dat" + newSortData[0]);

      return {
        ...state,
        filtered_sellers: newSortData,
      };

    case UPDATE_FILTERS:
      const { name, value } = action.payload
      return { ...state, filters: { ...state.filters, [name]: value } };

    case FILTER_SELLERS:
      let { all_sellers } = state;
      let tempFilterProduct = [...all_sellers];

      const { text } = state.filters;

      if (state.filters.text!=="") {
        tempFilterProduct = tempFilterProduct.filter((curElem) =>
            curElem.name.toLowerCase().includes(text)
        );
      }

      console.log(tempFilterProduct);

      return {
        ...state,
        filtered_sellers: tempFilterProduct,
      };
  }
};

export default filterReducer;