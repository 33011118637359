import React, { useEffect, useContext, useReducer } from 'react'
import reducer from '../reducers/filter_reducer'
import {
  LOAD_SELLERS,
  SET_GRIDVIEW,
  SET_LISTVIEW,
  UPDATE_SORT,
  SORT_SELLERS,
  UPDATE_FILTERS,
  FILTER_SELLERS,
  CLEAR_FILTERS, SET_COUNTRY_DATA, SET_CITY_DATA, SET_STATE_DATA,
} from '../actions'
import { useSellersContext } from './sellers_context'
import {Country} from "country-state-city";

const initialState = {
  filtered_sellers: [],
  all_sellers: [],
  grid_view: "list",
  sort: 'price-lowest',
  stateData:[],
  cityData:[],
  countryData:[],
  filters: {
    category:'',
    location:{},
    text: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    distance:''
  },
}

const FilterContext = React.createContext()

export const FilterProvider = ({ children }) => {
  const { sellers } = useSellersContext()
  const [state, dispatch] = useReducer(reducer, initialState)
  let data=Country.getAllCountries();
  useEffect(() => {
    dispatch({ type: LOAD_SELLERS, payload: sellers })
  }, [sellers])


  useEffect(() => {
    dispatch({type: SET_COUNTRY_DATA,payload: data});
  }, [data])


  useEffect(() => {
    dispatch({ type: FILTER_SELLERS })
    // dispatch({ type: SORT_SELLERS, payload: sellers })
  }, [state.filters])

  useEffect(() => {
    // dispatch({ type: FILTER_SELLERS })
    dispatch({ type: SORT_SELLERS, payload: sellers })
  }, [state.sort])
  // functions
  const setGridView = () => {
    dispatch({ type: SET_GRIDVIEW })
  }
  const setListView = () => {
    dispatch({ type: SET_LISTVIEW })
  }
  const updateSort = (e) => {
    // just for demonstration;
    // const name = e.target.name
    const value = e.target.value
    dispatch({ type: UPDATE_SORT, payload: value })
  }

  const updateFilters = (value,name) => {
    dispatch({ type: UPDATE_FILTERS, payload: { name, value } })
  }
  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS })
  }
  const setCityData = (data)=>{
    dispatch({type: SET_CITY_DATA,payload:data});
  }
  const setStateData = (data)=>{
    dispatch({type: SET_STATE_DATA,payload:data});
  }
  return (
    <FilterContext.Provider
      value={{
        ...state,
        setGridView,
        setListView,
        updateSort,
        updateFilters,
        clearFilters,
        setCityData,
        setStateData
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
// make sure use
export const useFilterContext = () => {
  return useContext(FilterContext)
}
