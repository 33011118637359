import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";

const FormField = ({
                       name,
                       type,
                       label,
                       placeholder,
                       onChangeYourInfo,
                       value,
                       isValid,
                        options,
                       isMultiple,
                        disabled
                   }) => {
    const [displayRequired, setDisplayRequired] = useState("hidden");
    const [redBorder, setRedBorder] = useState("border-[#d6d9e6]");

    useEffect(() => {
        if (!isValid && (value === undefined || value === "" || value === null)) {
            setDisplayRequired("block");
            setRedBorder("!border-[#ed3548]");
        }else {
            setDisplayRequired("hidden");
            setRedBorder("border-[#d6d9e6]");
        }
        // console.log(displayRequired);
        // console.log(isEmpty);
    }, [isValid,value]);

    return (
        <>
            <label className="text-primary-marineBlue font-[500] mb-2">
                {label}
            </label>
            {
                type === 'select' ?
                    <Select
                        isMultiple={isMultiple}
                        value={value}
                        onChange={onChangeYourInfo}
                        options={options}
                        primaryColor='orange'
                    /> : type === 'textarea' ?
                        <textarea
                            onChange={onChangeYourInfo}
                            name={name}
                            className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${redBorder} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                            placeholder={placeholder}
                            value={value}
                            disabled={disabled}
                        /> :
                        <input
                            onChange={onChangeYourInfo}
                            name={name}
                            className={`font-medium w-full mt-1 mb-2 p-2 pl-3 rounded-full rounded-lg border ${redBorder} text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
                            type={type}
                            placeholder={placeholder}
                            value={value}
                            disabled={disabled}
                        />
            }

            <p
                className={`${displayRequired} font-medium text-[14px] text-[#ed3548] right-[10px]`}
            >
                This field is required
            </p>

        </>
    );
};

export default FormField;